@font-face {
    font-family: 'Proxima Nova Bl';
    src: url('../fonts/ProximaNova-Black.woff2') format('woff2'),
        url('../fonts/ProximaNova-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url('../fonts/ProximaNovaT-Thin.woff2') format('woff2'),
        url('../fonts/ProximaNovaT-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url('../fonts/ProximaNova-Extrabld.woff2') format('woff2'),
        url('../fonts/ProximaNova-Extrabld.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
        url('../fonts/ProximaNova-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima-Nova-Bold';
    src: url('../fonts/ProximaNova-Bold.woff2') format('woff2'),
        url('../fonts/ProximaNova-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

:root {

    --fontSize22: 22px;
    --fontSize24: 24px;
    --fontSize28: 28px;
    --fontSize32: 32px
}

html {
    -webkit-text-size-adjust: none;
}

:focus {
    outline: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

embed,
iframe,
object {
    max-width: 100%;
}

body {
    position: relative;
    font-family: 'Proxima Nova Rg';
    min-height: 100vh;
    line-height: 1;
}

body::after {
    content: "";
    display: block;
    height: var(--padBottom);
}

ol,
ul {
    list-style: none;
}

a {
    text-decoration: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

cite,
em,
i {
    font-style: italic;
}

input,
textarea {
    -webkit-border-radius: 0px;
    border-radius: 0px;
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="file"],
input[type="number"],
textarea,
select {
    padding: 8px 10px;
    border: 1px solid #e4e4e4;
    width: 100%;
    margin: 0 0 10px;
    background-color: #fff;
    border-radius: 5px;
    height: 42px;
    font-size: 15px;
    color: #555555;
    font-family: 'Proxima Nova Rg';
}

input[readonly],
textarea[readonly] {
    background-color: #f6f6f6;
    cursor: no-drop;
}

textarea {
    height: 110px;
    resize: none;
}

input[type="reset"],
input[type="button"],
input[type="submit"],
.button,
button {
    color: #fff;
    display: inline-block;
    border-radius: 5px;
    margin: 15px 0px 10px 0px;
    padding: 14px 24px 14px;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    font-family: 'Proxima Nova Rg';
    cursor: pointer;
    background: #ec2f45;
    border: 0px;
    font-size: 15px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 2;
    -webkit-box-shadow: 0px 4px 9px 0px rgba(2, 2, 2, 0.08);
    box-shadow: 0px 4px 9px 0px rgba(2, 2, 2, 0.08);
}

input[type="reset"]:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.btn:hover,
.button:hover,
button:hover {
    color: #fff;
    background: #070802;
}

input[type="reset"]:active,
input[type="button"]:active,
input[type="submit"]:active {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.table_overflow {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 0px;
}

.table_overflow .table {
    margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #000000;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    margin: 0 0 15px;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 28px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

a {
    cursor: pointer;
    color: #8f7e80;
}

a:hover {
    color: #dd3144;
}

header.dev-portal {
    margin-top: 24px;
}

/* Code -- START */

.container {
    max-width: 1140px;
    margin: 0 auto;
}

.sm-container {
    max-width: 1020px;
    margin: 0 auto;
}

/* Cart List -- START */
.cart-block {
    margin: 25px 0;
}

.cart-block::after {
    display: inline-table;
    content: '';
    clear: both;
    min-height: 30px;
}

.cart-flx {
    display: flex;
    flex-flow: row wrap;
}

.cart-item {
    flex: 1 1 auto;
    max-width: 25%;
}

.cart-innr {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #ec2f45;
    overflow: hidden;
    box-shadow: -1px 2px 8px -1px rgb(153 204 0 / 0%);
    margin: 0 7px;
    transition: all .5s ease-in-out;
}

.cart-innr:hover {
    box-shadow: -1px 2px 8px -1px rgb(153 204 0 / 50%);
    transition: all .5s ease-in-out;
}

.cart-innr::before {
    content: "";
    position: absolute;
    background: linear-gradient(180deg, #fff 0% 10%, transparent 50% 100%);
    width: 100%;
    height: 100%;
    z-index: 1;
}

.cart-innr>div {
    position: absolute;
    z-index: 2;
}

.cart-top {
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
}

.cart-top img {
    max-width: 235px;
    margin: 20px 2px 0px;
}

.cart-top p {
    font-size: 24px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #020202;
    margin: 20px 2px 0px;
}

.cart-bottom {
    bottom: 0;
    padding: 20px;
    width: 100%;
    background: rgb(153 204 0 /75%);
    transition: all .5s ease-in-out;
}

.cart-innr:hover .cart-bottom {
    background: rgb(153 204 0 /85%);
    transition: all .5s ease-in-out;
}

.cart-bottom p {
    font-size: 16px;
    line-height: 1.1;
    color: #fff;
    text-align: center;
}

.cart-back {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Cart List -- END */

/* Checkout -- START */

.checkout-block {
    margin: 65px 0 15px;
}

.checkout-title {
    font-size: 38px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #ec2f45;
    margin: 0 0 25px;
}

.acc-ttflx {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 25px;
}

.acc-ttflx .checkout-title {
    margin: 0 0 0;
}

.acc-ttflx .acc-back {
    display: flex;
    align-items: center;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.acc-ttflx .acc-back:hover {
    color: #ec2f45;
}

.acc-ttflx .acc-back .fa {
    font-size: 23px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    line-height: 0;
    margin: 0 6px 0 0;
}

.checkout-table,
.right-table-outer-wrap {
    padding: 15px 15px;
    box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, .14);
    border-radius: 6px;
    background: #fff;
    width: 100%;
}

.checkout-table table tbody tr td span.offer-tag>img.tag-icon {
    width: 16px;
    margin-right: 5px;
}

.prod-custom-grp {
    padding: 20px 15px 10px;
    background: #f6f6f6;
    border-radius: 4px;
    margin: 0 0 15px;
}

.checkout-table .table_overflow {
    margin-bottom: 0px;
}

.checkout-table table {}

.checkout-table table thead {}

.checkout-table table thead tr {
    padding: 0 0 5px;
    border-bottom: 1px solid #959699;
}

.checkout-table table thead tr td {
    font-size: 18px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    line-height: 1.2;
    padding: 5px 5px 5px 10px;
    background: #f8f8f8;
    color: #2B2E34;
}

.checkout-table table thead tr td {
    width: 130px;
}

.checkout-table table thead tr td:last-child {
    width: 40px;
}

.checkout-table table tbody {}

.checkout-table table tbody tr {
    padding: 0 0 5px;
    border-bottom: 1px solid #959699;
}

.checkout-table table tbody tr.no-line {
    border-bottom: 0px;
}

.checkout-table table tbody tr.no-line.lin-btn td {
    vertical-align: bottom;
}

.checkout-table table tbody tr.no-line.lin-btn td.vrt-mid {
    vertical-align: middle;
    width: 150px;
}

.checkout-table table tbody tr:nth-child(odd) td {
    background: #fbfbf2;
}

.checkout-table table tbody tr td:first-child {}

.checkout-table table tbody tr td:last-child {
    width: 130px;
}

.checkout-table table tbody tr td {
    position: relative;
    font-family: 'Proxima Nova Rg';
    font-size: 16px;
    color: #2B2E34;
    padding: 5px 5px 5px 0px;
    vertical-align: middle;
}

.checkout-table table tbody tr td.prt-title .prtf-flx {
    display: flex;
    flex-flow: row nowrap;
}

.checkout-table table tbody tr td .prtf-flx .prt-ico {
    margin-left: 5px;
}

.checkout-table table tbody tr td.table-tol {
    width: 130px;
}

.ico-del {
    cursor: pointer;
}

.ico-del .fa {
    color: #000;
}

.ico-del:hover .fa {
    color: #d30000;
}

.checkout-table table tbody tr td.table-del {
    width: 40px;
}

.checkout-table table tbody td.table-qty {
    width: 165px;
}

.checkout-table table tbody td .prot-flx {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
}

.checkout-table table tbody td .prot-info {
    display: block;
    flex: 1 1 auto;
    max-width: calc(100% - 35px);
}

.checkout-table table tbody tr td .close-product {
    position: relative;
    display: block;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    opacity: 1;
    margin-top: 2px;
    margin-right: 8px;
    cursor: pointer;
}

.checkout-table table tbody tr td .close-product::before,
.checkout-table table tbody tr td .close-product::after {
    content: "";
    content: "";
    position: absolute;
    left: 15px;
    content: ' ';
    height: 15px;
    width: 1.5px;
    background-color: #000;
}

.checkout-table table tbody tr td .close-product:hover::before,
.checkout-table table tbody tr td .close-product:hover::after {
    background-color: rgb(255, 51, 0);
}

.checkout-table table tbody tr td .close-product::before {
    transform: rotate(45deg);
}

.checkout-table table tbody tr td .close-product::after {
    transform: rotate(-45deg);
}

.checkout-table table tbody tr td .form-control {
    position: relative;
    font-size: 18px;
    color: #2B2E34;
    width: 50px;
    border: 0px;
    outline: 0px;
    z-index: 19;
}

.checkout-table table tbody tr td .form-control::-webkit-inner-spin-button,
.checkout-table table tbody tr td .form-control::-webkit-outer-spin-button {
    opacity: 1;
    background-color: transparent;
    background: transparent;
    color: #2B2E34;
}

.checkout-table table tbody tr td span {}

.checkout-table table tbody tr td span.offer-tag {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 3px 6px;
    background: #f6f6f6;
    width: max-content;
    border-radius: 5px;
    margin: 4px 0 0;
    color: #eb2f47;
    font-size: 12px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.checkout-table table tbody tr td span.strong {
    font-size: 20px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #020202;
}

.checkout-table table tbody tr.total td {
    padding: 5px 0;
}

.checkout-table table tbody tr td .button {
    display: block;
    width: calc(100% - 5px);
    margin: 15px auto 0;
    font-size: 17px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    padding: 8px 6px;
    text-transform: capitalize;
    border-radius: 0px;
    box-shadow: none;
}

.checkout-table table tbody tr td .button.back-btn {
    color: #000000;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    background: transparent;
    width: 100%;
    padding: 0px 2px;
    text-align: left;
}

.checkout-table table tbody tr td .button.back-btn:hover {
    color: #ec2f45;
}

.checkout-table table tbody tr.total td {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.checkout-table table tbody tr td a.editbtn.button {
    padding: 6px 12px 6px;
    color: #fff;
    overflow: initial;
    width: auto;
    display: inline-block;
    border-radius: 5px;
    margin: 0;
}

/* Checkout -- END */

/* Catergory & Product List -- START */

.cater-block {
    margin: 65px 0;
}

.cater-sec {}

.cater-sec h1 {
    font-size: var(--fontSize32);
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    line-height: 1.3;
    color: #ec2f45;
    text-align: center;
    margin: 0 0 50px;
}

.cater-flx {
    display: flex;
    flex-flow: row wrap;
}

.cater-item {
    flex: 1 1 auto;
    max-width: 50%;
    width: 100%;
    text-align: center;
    padding: 7px;
}

.cater-innr {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: var(--fontSize28);
    line-height: 1.3;
    color: #ec2f45;
    padding: 5px;
    min-height: 160px;
    border: 1px solid #ec2f45;
    box-shadow: 1px 2px 4px 1px rgb(0 0 0 / 0%);
    transition: all .5s ease-in-out;
}

.cater-innr:hover {
    box-shadow: 1px 2px 4px 1px rgb(0 0 0 / 15%);
}

.cater-prot-flx {
    display: flex;
    flex-flow: row wrap;
}

.prot-itme {
    flex: 1 1 auto;
    max-width: 25%;
    width: 100%;
    text-align: center;
}

.prot-innr {
    display: block;
    margin: 0 10px 40px;
}

.prot-ico {}

.prot-ico img {}

.prot-innr span {
    display: block;
    font-size: 18px;
    color: #ec2f45;
    max-width: 195px;
    margin: 15px auto 0;
}

/* Catergory & Product List --- END */

/* Extra Popup -- START */

.info-poup {}

.info-bdy {}

.info-poup .order-top h4 span {
    position: relative;
    padding-left: 25px;
    padding-right: 30px;
    display: block;
}

.info-poup .order-top h4 img {
    position: absolute;
    width: 22px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}

.info-item {
    position: relative;
    text-align: left;
    padding: 0 0 15px;
    border-bottom: 1.8px solid #D9D9D9;
    margin: 0 0 15px;
}

.info-item:last-child {
    padding: 0 0 0;
    border-bottom: 0px;
    margin: 0 0 0;
}

.info-innr h5 {
    margin: 0 0 8px;
}

.info-innr h5 span {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    padding-right: 7px;
}

.info-innr h5 span::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.info-innr p {
    font-size: 16px;
    line-height: 1.4;
}

.info-innr p span {
    font-weight: bold;
}

.info-innr p strong {
    font-weight: bold;
    text-transform: uppercase;
}

.info-innr p a {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #eb5466;
    line-height: 1;
    text-decoration: underline;
}

.info-innr p a:hover {
    color: #ec2f45;
}

.modal.prt-poup .close {
    opacity: 1;
    right: 8px;
    border-bottom-left-radius: 18px;
}

.modal.prt-poup .close:hover::before,
.modal.prt-poup .close:hover::after {
    background-color: #f00;
}

.prt-bdy-img {
    max-width: 400px;
}

.prt-bdy-img img {
    max-width: 100%;
}

.prt-dsc {
    display: flex;
    justify-content: center;
    margin: 0px 0 0px;
}

.prt-dsc .pop-prt-txt {
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
    color: #000;
    padding: 8px 8px;
    border-radius: 2px;
}

.prt-dsc .pop-prt-txt:empty {
    display: none;
}

.btn-center.btn-field {
    text-align: center;
    padding: 10px 0 15px;
    width: 100%;
}

/* Extra Popup -- END */

/* ThankYou Page -- START */

.thank-section {
    margin: 65px 0;
}

.thank-section .sm-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
}

.thank-block {
    position: relative;
    text-align: center;
    width: 100%;
    background: #fff;
    border: 1px solid #eef2e2;
    box-shadow: 1px 2px 12px 3px rgb(0 0 0 / 15%);
    border-radius: 5px;
}

.thank-head {
    padding: 25px 10px 15px;
    background: #eef2e2;
}

.thank-head h3 {
    font-size: var(--fontSize24);
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    margin: 5px 0 5px;
}

.thank-block p {
    font-size: 14px;
    line-height: 1.5;
    color: #737373;
}

.thank-ico {
    margin: 0 0 15px;
}

.thank-ico .fa {
    font-size: 50px;
    color: #9c0;
}

.thank-body {
    padding: 25px 10px;
}

.thank-block h1 {
    font-size: var(--fontSize28);
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    line-height: 1.3;
    text-transform: capitalize;
    margin: 0 0 5px;
}

.thank-btn {
    font-size: 16px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    box-shadow: none;
    border-radius: 0px;
    text-transform: capitalize;
    padding: 10px 15px;
    margin: 10px 0 0;
}

/* ThankYou Page -- END */

/* Order Detail Popup -- START */

.order-pop.modal .close {
    top: 10px;
    right: 10px;
    opacity: 1;
}

.order-pop.modal .close:hover::before,
.order-pop.modal .close:hover::after {
    background-color: #f90000;
}

.order-top {}

.order-top .head {
    padding: 10px 15px;
    background: #f2f4df;
    text-align: center;
}

.order-top h4 {
    font-size: var(--fontSize22);
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    margin: 0 0 0;
    padding: 0px 20px 0px 0px;
    text-align: center;
}

.info-poup .order-top h4 span {
    position: relative;
    padding-left: 30px;
    padding-right: 30px;
    display: block;
}

.order-top .bottom {
    padding: 20px 15px;
    text-align: center;
}

.order-top .bottom h3 {
    font-size: var(--fontSize32);
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.order-top .bottom p {
    font-size: var(--fontSize24);
    margin: 0 0 10px;
}

.order-top .bottom p span {}

.order-top .bottom p:last-child {
    margin-bottom: 0;
}

.order-status {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 25px 0 35px;
    z-index: 1;
}

.order-status::after {
    content: "";
    position: absolute;
    top: 15px;
    display: block;
    width: 100%;
    height: 2px;
    border-bottom: 1px dashed #9c0;
    z-index: -1;
}

.order-han {
    flex: 1 1 auto;
    max-width: 50%;
}

.order-han.left {
    text-align: left;
}

.order-han.right {
    text-align: right;
}

.order-innr {}

.order-innr h6 {
    font-size: var(--fontSize22);
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    margin: 0 0 5px;
}

.order-innr p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;
}

.order-innr h3 {
    font-size: var(--fontSize32);
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    margin: 15px 0 0;
}

.order-innr h3 span {
    display: block;
    font-size: var(--fontSize22);
    margin: 0 0 5px;
}

.vendor-list-table .order-top .head {
    text-align: initial;
    padding: 10px 40px;
}

.title-left .order-top .head {
    text-align: initial;
    padding: 10px 40px;
}

.order-top .bottom.vendor-details {
    text-align: unset !important;
    padding: 30px 40px 30px;
}

.order-top .bottom.vendor-details p {
    font-size: 18px;
    font-weight: 400;
    word-wrap: break-word;
}

.order-top .bottom.vendor-details p .labeltxt {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.vendor-list-table .order-top .bottom.vendor-details {
    text-align: initial;
    padding: 10px 0;
}

.order-pop .order-top .bottom.vendor-details {
    padding: 10px 0;
}


/** order pagination **/
.rdt_Pagination {
    padding: 5px 0 0;
    margin: 5px 0 5px;
}

.rdt_Pagination button[type="button"] {
    background: #f2f4df;
    fill: #ec2f45;
    margin: 0 4px;
}

.rdt_Pagination button[type="button"]:hover,
.rdt_Pagination button[type="button"]:active {
    background: #ec2f45;
    fill: #f2f4df;
}

.rdt_Pagination button[type="button"]:disabled {
    background: #ccc;
    fill: #969696;
}

.item-title {
    font-size: var(--fontSize22);
    color: #fff;
    padding: 7px 15px;
    background: rgba(51, 46, 32, 0.5);
}

.ico {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    background: transparent;
    margin: 0 0 10px;
}

.bike-ico {
    background-image: url(../images/motorcycle.png);
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0 50px 0 0;
    background-color: #fff;
    text-align: left;
}

.loca-ico {
    background-image: url(../images/pin.png);
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0 0 0 30px;
    background-color: #fff;
    text-align: right;
}

.popup-btn {
    font-size: var(--fontSize22);
}

/* Order Detail Popup -- END */

/* Card Login Popup -- START */
.modal-pay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.pay-popup {
    position: fixed;
    text-align: center;
    top: 50%;
    max-width: 450px;
    width: 100%;
    padding: 10px;
    background: #fff;
    border: 3px solid #f3fdd6;
    border-radius: 5px;
    box-shadow: 1px 2px 3px 3px rgb(0 0 0 / 15%);
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
}

.modal-overly {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(40, 40, 40, 0.75);
}

.popup-close {
    position: absolute;
    right: 0;
    top: 0;
    width: 25px;
    height: 25px;
    opacity: .5;
    cursor: pointer;
}

.popup-close:hover {
    opacity: 1;
}

.popup-close::before,
.popup-close::after {
    content: "";
    position: absolute;
    left: 15px;
    content: ' ';
    height: 20px;
    width: 2.2px;
    background-color: #000;
}

.popup-close::before {
    transform: rotate(45deg);
}

.popup-close::after {
    transform: rotate(-45deg);
}

.pay-inner {
    position: relative;
    padding: 50px 0 0;
}

.crd-logo {
    position: absolute;
    top: -30%;
    right: 0;
    left: 0;
    z-index: -1;
}

.crd-logo span {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #9c0;
    border: 6px solid #fff;
    width: 120px;
    height: 120px;
    padding: 10px;
    border-radius: 50%;
    margin: 0 auto;
}

.crd-logo img {
    object-fit: contain;
    max-width: 100px;
}

.pay-popup form {}

.pay-popup .crd-title {
    text-align: center;
}

.pay-popup .crd-title span {
    position: relative;
    display: inline-block;
    font-size: 20px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    padding: 15px 0 0;
    margin: 0 0 25px;
}

.pay-popup .crd-title span::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    width: 65%;
    height: 2px;
    background: #9c0;
}

.pay-popup .crd-row>div {}

.modal .close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 25px;
    height: 25px;
    display: inline-block;
    opacity: .5;
    cursor: pointer;
    z-index: 99;
}

.modal .close:hover {
    opacity: 1;
}

.modal .close::before,
.modal .close::after {
    content: "";
    position: absolute;
    left: 15px;
    content: ' ';
    height: 20px;
    width: 2.2px;
    background-color: #000;
}

.modal .close::before {
    transform: rotate(45deg);
}

.modal .close::after {
    transform: rotate(-45deg);
}

.crd-row {}

.crd-flx {}

.crd-flx label {}

.crd-flx .form-control {
    color: #32325d;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    border: 0px;
    border-bottom: 1px solid #e4e4e4;
}

.crd-flx .form-control::placeholder {
    color: #c0c0c0;
    opacity: 1;
}

.crd-flx .form-control:-ms-input-placeholder {
    color: #c0c0c0;
    opacity: 1;
}

.crd-flx .form-control::-webkit-input-placeholder {
    color: #c0c0c0;
    opacity: 1;
}

.pay-popup button {
    font-family: 'Proxima Nova Rg';
    font-size: 16px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    border-radius: 0px;
    padding: 10px 7px;
    margin: 20px 0 10px;
    letter-spacing: 1.5px;
    text-transform: capitalize;
}

/* Card Login Popup -- END */

/* Small ToolTip Popup -- START */

.sm-popup {
    position: fixed;
    max-width: 255px;
    top: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 99999;
}

.sm-popup#success .sm-popup-innr {
    background: #c8eec9;
}

.sm-popup#warning .sm-popup-innr {
    background: #ffcb6c;
}

.sm-popup#danger .sm-popup-innr {
    background: #ffabab;
}

.sm-popup-innr {
    position: relative;
    user-select: none;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 1px 2px 14px 0px rgb(0 0 0 / 20%);
}

.sm-popup .popup-close {
    opacity: 1;
    width: 15px;
    height: 15px;
    right: inherit;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}

.sm-popup .popup-close::before,
.sm-popup .popup-close::after {
    height: 15px;
}

.sm-popup#success .popup-close::before,
.sm-popup#success .popup-close::after {
    background: #048307;
}

.sm-popup#warning .popup-close::before,
.sm-popup#warning .popup-close::after {
    background: #654100;
}

.sm-popup#danger .popup-close::before,
.sm-popup#danger .popup-close::after {
    background: #650000;
}

.popup-close:hover::before,
.popup-close:hover::after {
    background: #f90000 !important;
}

.sm-cnt {
    max-width: calc(100% - 30px);
    margin-left: 15px;
    text-align: left;
}

.sm-cnt p {
    font-size: 16px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.sm-popup#success .sm-cnt p {
    color: #043105;
}

.sm-popup#warning .sm-cnt p {
    color: #774d00;
}

.sm-popup#danger .sm-cnt p {
    color: #4a0404;
}

/* Small ToolTip Popup -- END */

/* DatePicker -- START */
.react-datepicker {
    font-family: 'Calibri' !important;
}

.react_timepicker .css-bg1rzq-control {
    min-height: 49px;
    border-radius: 0;
}

.react_timepicker .css-1szy77t-control {
    min-height: 49px;
    border-radius: 0;
}

.react_timepicker .css-1hwfws3 {
    padding: 2px 22px;
}

.react_timepicker input[type=text] {
    height: 100%;
}

.react_datepicker .css-yk16xz-control {
    min-height: 48px;
    border-radius: 0;
}

.react_datepicker .css-1pahdxg-control {
    min-height: 48px;
    border-radius: 0;
}

.react_datepicker .react-datepicker-wrapper,
.react_datepicker .react-datepicker__input-container {
    display: block;
}

.react_datepicker .react-datepicker {
    font-size: 1rem;
}

.react_datepicker .react-datepicker__current-month,
.react_datepicker .react-datepicker-time__header {
    font-size: 1.2rem;
    font-family: 'Calibri-Bold';
    font-weight: normal
}

.react_datepicker .react-datepicker__day-name,
.react_datepicker .react-datepicker__day,
.react_datepicker .react-datepicker__time-name {
    width: 2rem;
}

.react_datepicker .react-datepicker__day-name,
.react_datepicker .react-datepicker__day,
.react_datepicker .react-datepicker__time-name {
    width: 2.7rem;
}

.react_datepicker .react-datepicker__time-container {
    width: 120px;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    padding: 0;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 120px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
    background-color: #8cbc06 !important
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
    background-color: #fb9f0e !important;
}

.react-datepicker__header {
    background-color: #8cbc06 !important;
    color: #fff;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    border-bottom-color: #8cbc06 !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: #8cbc06 !important;
}

.react-datepicker__navigation {
    margin: 0
}

.react-datepicker__navigation--previous {
    border-right-color: #fff !important;
    box-shadow: none;
}

.react-datepicker__navigation--next {
    border-left-color: #fff !important;
    box-shadow: none;
    opacity: 1;
}

.react-datepicker__navigation-icon::before {
    border-color: #fff !important;
}

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next:hover {
    background: none
}

.react-datepicker__month-dropdown-container--select select,
.react-datepicker__year-dropdown-container--select select {
    height: 36px;
    padding: 5px 10px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: #fff !important;
}

.react-datepicker__day-name {
    color: #fff !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: #8cbc06;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
    background-color: #8cbc06 !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    transform: translate(40px, 0px) !important;
}

.react-datepicker-popper {
    z-index: 9 !important;
    padding-top: 0 !important;
}

/* DatePicker -- END */

/* Common Popup -- START */
.modal-table {
    border: 1px solid #959699;
    border-top: 0;
    margin-bottom: 25px;
    margin-top: 10px;
}

.modal-table .checkout-table,
.modal-table .right-table-outer-wrap {
    box-shadow: none;
    padding: 0;
}

.modal-table table {
    margin-bottom: 0px;
}

.modal-table thead {}

.modal-table thead tr {}

.modal-table thead tr td {}

.modal-table tbody tr {}

.modal-table tbody tr td {}

.modal-table tbody tr td .form-control {
    position: relative;
    font-size: 18px;
    color: #2B2E34;
    width: 50px;
    border: 0px;
    outline: 0px;
    z-index: 19;
    max-width: 100px;
}

.modal-table tbody tr td .form-control::-webkit-inner-spin-button,
.modal-table tbody tr td .form-control::-webkit-outer-spin-button {
    opacity: 1;
    background-color: transparent;
    background: transparent;
    color: #2B2E34;
}

.modal-table .table_overflow {
    padding: 0 15px;
}

.my-order-details-pop .modal-table {
    margin-bottom: 14px;
}

.dt-flx {
    display: flex;
    flex-flow: row wrap;
    max-width: 500px;
    margin: 50px auto 15px 0;
}

.dt-flx>div {
    flex: 1 1 auto;
    max-width: 50%;
}

.dt-flx>div .dt-innr {
    margin-right: 15px;
}

.dt-flx>div .dt-innr>span {
    display: inline-block;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    margin: 0 0 10px;
}

.dt-flx .delivery-date {}

.dt-flx .delivery-time {}

.modal.prt-poup.prt-img-poup .close {
    background: transparent;
    top: 15px;
    right: 10px;
}

/* Common Popup -- END */

.dt-title {
    font-size: var(--fontSize32);
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 0 0 15px;
}

.dt-title .dts-title {
    font-size: var(--fontSize32);
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #ec2f45;
    margin: 0 10px 0 0;
}

.dt-title span {
    font-size: 16px;
}

.dt-flx>div .dt-innr select {
    padding: 10px;
}

/* Loader -- START */
.load-flx {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 25px;
}

.load-smll #loading {
    border-width: 3px;
}

.load-lag #loading {
    width: 70px;
    height: 70px;
    border-width: 10px;
}

#loading {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 8px solid rgb(236 47 69 / 50%);
    border-radius: 50%;
    border-top-color: #ec2f45;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

/* Loader -- END */

/* My Account -- START */
.acc-block {
    position: relative;
    margin: 65px 0;
}

.acc-table {}

.acc-table table {}

.acc-table thead {}

.acc-table thead tr {
    padding: 0 0 5px;
    border-bottom: 1px solid #959699;
}

.acc-table thead tr td {
    font-size: 20px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    line-height: 1.2;
    padding: 0 5px 0 0;
    color: #2B2E34;
}

.acc-table thead tr td .fa {}

.acc-table tbody {}

.acc-table tbody tr {
    padding: 0 0 5px;
    border-bottom: 1px solid #959699;
}

.acc-table tbody tr td {
    position: relative;
    font-family: 'Proxima Nova Rg';
    font-size: 16px;
    color: #2B2E34;
    padding: 5px 5px 5px 0px;
    vertical-align: middle;
}

.acc-table tbody tr td .acc-btn {
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    padding: 6px 15px;
    color: #fff;
    padding: 6px 15px;
    background: #ec2f45;
    transition: all .5s ease-in-out;
}

.acc-table tbody tr td .acc-btn:hover {
    background: #000;
    transition: all .5s ease-in-out;
}

.acc-table tbody tr.no-line {
    border-bottom: 0px;
}

.acc-table tbody tr.total td span {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

/* My Account -- END */

/* Login Screen Design -- Jagan */

.login {
    padding: 0%;
    margin: 75px auto 60px;
    height: 100%;
}

.form {
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    border-radius: 10px;
    max-width: 360px;
    margin: 0 auto 60px;
    padding: 45px;
    text-align: center;
    box-shadow: 0px 4px 7px 1px #ccc;
}

.form .login-form h2 {
    font-size: 30px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    margin: 0 0 20px;
}

.form input {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    border-radius: 5px;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
}

.form input:focus {
    background: #dbdbdb;
}

.form button {
    font-size: 18px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    padding: 10px 15px;
    text-transform: capitalize;
    border-radius: 0px;
}

.form .login-form button {
    min-width: 160px;
    border-radius: 4px;
    padding: 12px 15px;
}

.form button:active {
    background: #395591;
}

.form span {
    font-size: 75px;
    color: #4b6cb7;
}

.err-msg {
    font-size: 14px;
    color: red;
    text-align: left;
}

.error-msg {
    font-size: 14px;
    background: #b62334;
    display: inline-block;
    position: absolute;
    left: 10px;
    top: calc(100% - 8px);
    text-align: left;
    padding: 4px 10px;
    color: #FFF;
    border-radius: 4px;
    margin: -2px 0 0;
    z-index: 4;
}

.error-msg::before {
    position: absolute;
    content: '';
    border: 8px solid;
    border-color: transparent transparent #b62334 transparent;
    top: -15px;
}

.error-msg.login-error {
    position: relative;
    left: -10px;
}

.error-msg.login-error::before {
    border-color: #b62334 transparent transparent transparent;
    top: auto;
    bottom: -15px;
}

.success-msg {
    font-size: 14px;
    color: green;
    text-align: left;
}

.txt-green {
    color: green;
    cursor: pointer;
}

.sweet-alert button {

    color: #fff;
    display: inline-block;
    border-radius: 0px;
    padding: 9px 24px;
    cursor: pointer;
    border: 0px;
    font-size: 15px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;


}


/* Login Screen Design End -- Jagan */

.StripeElement {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

/* Code -- END */


/* 01/04/2022 -- START */

body.split::after {
    display: none;
}

.dashboard-block {
    display: flex;
    flex-flow: row wrap;
    max-width: 100vw;
    min-height: 100vh;
}

.dash-right {
    flex: 1 1 auto;
    max-width: 250px;
    height: 100%;
    z-index: 3;
}

.dash-left {
    position: relative;
    flex: 1;
    /* max-width: calc(100% - 250px);
    z-index: 2; */
    min-height: 100vh;
    padding: 0 15px;
    transition: all 0.5s ease;
}

.dash-left::after {
    content: "";
    display: block;
    height: var(--padBottom);
}

.dash-left header .sm-container {
    max-width: calc(100% - 30px);
}

.dash-left .cart-block .container {
    padding: 0 15px;
}

.color-red {
    color: #ec2f45;
}

.color-black {
    color: #222;
}


/* 01/04/2022 -- END */

/* Filter Table -- START */

.full-container {
    max-width: 1460px;
    padding: 0 15px;
    margin: 0 auto;
}

.rdt_Table * {
    font-family: 'Proxima Nova Rg';
    font-size: 15px;
    color: #2B2E34;
}

.rdt_Table * div:first-child {
    white-space: initial !important;
    overflow: initial !important;
    text-overflow: unset !important;
}

.rdt_Table a.button {
    padding: 4px 10px;
    color: #fff;
    overflow: initial;
}

.rdt_Table .action-btns>a.button {
    padding: 4px 8px;
    min-width: 26px;
}

.rdt_Table .action-btns a button.button {
    margin: 0;
    padding: 6px 8px;
}

.rdt_Table button i,
.rdt_Table a.button i {
    color: #FFF;
    font-size: 13px;
}

.rdt_Table .rdt_TableBody a.button {
    margin: 0 2px;
}

.rdt_TableHead * {
    font-size: 15px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.table_overflow .rdt_TableBody .rdt_TableRow .rdt_TableCell>div,
.table_overflow .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable>div,
.checkout-table .rdt_TableBody .rdt_TableRow .rdt_TableCell>div,
.checkout-table .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable>div {
    white-space: normal;
    overflow: initial;
}

.rdt_Table button {
    font-size: 13px;
    padding: 9px 12px 8px;
    line-height: 1;
}

.table_overflow .rdt_Table {
    overflow: auto;
}

.table_overflow .rdt_Table .rdt_TableHead {
    border: 1px solid #e4e4e4;
    border-bottom: none;
}

.table_overflow .rdt_Table .rdt_TableBody {
    border: 1px solid #e4e4e4;
    border-top: none;
}

.table_overflow .rdt_Table .rdt_TableHeadRow,
.table_overflow .rdt_Table .rdt_TableRow {
    border-bottom: none;
}

.table_overflow .rdt_Table .rdt_TableHeadRow {
    background: #FFF;
    border-bottom: 1px solid #e4e4e4;
}

.table_overflow .rdt_Table .rdt_TableRow:nth-of-type(even) {
    background: #f8f8f8;
}

/*
.rdt_TableBody .rdt_TableRow:last-child {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #959699;
}
*/
.rdt_TableBody .rdt_TableRow img {
    cursor: pointer;
}

.rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="4"] {
    min-width: 130px;
}

.rdt_Table .rdt_TableBody .rdt_TableCell[data-column-id="4"] {
    min-width: 130px;
}

.table_overflow .rdt_TableHeadRow .rdt_TableCol[data-column-id="1"],
.table_overflow .rdt_TableBody .rdt_TableCell[data-column-id="1"],
.checkout-table .rdt_TableHeadRow .rdt_TableCol[data-column-id="1"],
.checkout-table .rdt_TableBody .rdt_TableCell[data-column-id="1"] {
    position: sticky;
    left: 0px;
    z-index: 2;
    /* background: #fff; */
    padding-left: 10px;
}

.table_overflow .rdt_TableHeadRow .rdt_TableCol[data-column-id="2"],
.table_overflow .rdt_TableBody .rdt_TableCell[data-column-id="2"],
.checkout-table .rdt_TableHeadRow .rdt_TableCol[data-column-id="2"],
.checkout-table .rdt_TableBody .rdt_TableCell[data-column-id="2"] {
    position: sticky;
    left: 100px;
    z-index: 2;
    /*background: #fff;*/
    padding-left: 10px;
}

.checkout-table .products-table .rdt_TableHeadRow .rdt_TableCol[data-column-id="1"],
.checkout-table .products-table .rdt_TableBody .rdt_TableCell[data-column-id="1"] {
    max-width: 120px;
    text-align: center;
    justify-content: center;
}

.checkout-table .products-table .rdt_TableHeadRow .rdt_TableCol[data-column-id="2"],
.checkout-table .products-table .rdt_TableBody .rdt_TableCell[data-column-id="2"] {
    min-width: 220px;
}

.checkout-table .products-table .rdt_TableHeadRow .rdt_TableCol[data-column-id="3"],
.checkout-table .products-table .rdt_TableBody .rdt_TableCell[data-column-id="3"] {
    max-width: 110px;
    text-align: center;
    justify-content: center;
}

.checkout-table .products-table .rdt_TableHeadRow .rdt_TableCol[data-column-id="4"],
.checkout-table .products-table .rdt_TableBody .rdt_TableCell[data-column-id="4"] {
    max-width: 110px;
    text-align: center;
    justify-content: center;
}

.checkout-table .products-table .rdt_TableHeadRow .rdt_TableCol[data-column-id="5"],
.checkout-table .products-table .rdt_TableBody .rdt_TableCell[data-column-id="5"] {
    text-align: center;
    justify-content: center;
}

.products-table .rdt_TableBody .rdt_TableRow img {
    cursor: pointer !important;
    pointer-events: auto;
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.rdt_TableHeadRow .rdt_TableCol[data-column-id="7"],
.rdt_TableBody .rdt_TableCell[data-column-id="7"] {
    min-width: 125px;
}

.vendor-list-table .rdt_TableHeadRow .rdt_TableCol[data-column-id="2"],
.vendor-list-table .rdt_TableBody .rdt_TableCell[data-column-id="2"] {
    min-width: 190px;
}

.vendor-list-table .rdt_TableBody button,
.vendor-list-table .rdt_TableBody .button {
    margin: 0 3px;
    font-size: 13px;
    padding: 4px 8px;

}

.change-pwd span.labeltxt {
    display: inline-block;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-size: 14px;
    margin: 0 0 8px;
}

.change-pwd .btn-field {
    text-align: center;
}

/*** Check box style - START ****/

.check-box {
    display: block;
    margin-bottom: 10px;
}

.check-box input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.check-box label {
    position: relative;
    cursor: pointer;
}

.check-box label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #ec2f45;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%), inset 0px -15px 10px -12px rgb(0 0 0 / 10%);
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    top: -3px;
    cursor: pointer;
    margin-right: 7px;
    border-radius: 2px;
}

.check-box input:checked+label:before {
    border-color: #ec2f45;
}

.check-box input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 6px;
    width: 5px;
    height: 9px;
    border: solid #ec2f45;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}


/**** orders-pop header ***/
.order-pop .order-top .bottom {
    background: #FFF;
    text-align: left;
    padding: 20px;
}

.order-pop .order-top .bottom h3,
.order-pop .order-top .bottom h4,
.order-pop .order-top .bottom p {
    color: #332e20;
}

.order_account_details {
    padding: 20px 0 0;
}

.order-pop .order-top .bottom.order-flex {
    background: #FFF;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    justify-content: space-between;
    padding: 20px 40px;
}

.bottom.order-flex .order_no_details {
    width: 40%;
    max-width: 400px;
}

.bottom.order-flex .order_account_details {
    width: 40%;
    max-width: 400px;
    padding: 0;
}

.bottom.order-flex .order_account_details.abandoned-cart-left {
    width: 50%;
    max-width: initial;
}

.order_no_details p,
.order_account_details p {
    display: block;
}

.order_no_details p span.ord-det-txt,
.order_account_details p span.ord-det-txt {
    width: auto;
}

.order_no_details p span.pay,
.order_account_details p span.pay,
.order_no_details p span.date,
.order_account_details p span.date {
    width: auto;
}

.order-top .bottom .order_no_details h3,
.order-top .bottom .order_no_details h3 {
    font-size: var(--fontSize24);
}

.order-top .bottom .order_no_details p,
.order-top .bottom .order_account_details p {
    font-size: 16px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.order-top .bottom .order_no_details p span,
.order-top .bottom .order_account_details p span {
    font-weight: 400;
    font-family: 'Proxima Nova Rg';
}

/* check switch button */

.switch-button .knobs,
.switch-button .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.switch-button {
    position: relative;
    display: inline-block;
    width: 74px;
    height: 40px;
    margin: 0px 0px -15px 25px;
    overflow: hidden;
}

.switch-button .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.switch-button .knobs {
    z-index: 2;
}

.switch-button .layer {
    width: 100%;
    background-color: #b5b5b5;
    transition: 0.3s ease all;
    z-index: 1;
    border-radius: 30px;
    height: 40px;
}

.switch-button .knobs:before,
.switch-button .knobs:after,
.switch-button .knobs span {
    position: absolute;
    top: 5px;
    width: 20px;
    height: 25px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 3px;
    border-radius: 2px;
    transition: 0.3s ease all;
}

.switch-button .knobs:before,
.switch-button .knobs:after {
    color: #FFF;
    z-index: 1;
}

.switch-button .knobs:before {
    content: "OFF";
    left: 7px;
}

.switch-button .knobs:after {
    content: "ON";
    right: 13px;
}

.switch-yes-no.switch-button .knobs:before {
    content: "NO";
    left: 10px;
}

.switch-yes-no.switch-button .knobs:after {
    content: "YES";
    right: 15px;
}

.switch-button .knobs span {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    left: 37px;
    background-color: #e5e5e5;
    z-index: 2;
}

.switch-button .checkbox:checked+.knobs span {
    left: 6px;
    background-color: #FFF;
}

.switch-button .checkbox:checked~.layer {
    background-color: #8ecf65;
}

.switch-button.stripe-mode {
    width: 100px;
}

.switch-button.stripe-mode .knobs span {
    width: 45px;
    border-radius: 30px;
    left: 48px;
    background: #FFF;
}

.switch-button.stripe-mode .knobs:before {
    content: "Live";
    left: 12px;
    font-size: 14px;
}

.switch-button.stripe-mode .knobs:after {
    content: "Test";
    right: 22px;
    font-size: 14px;
}

.switch-button.stripe-mode .layer {
    background-color: #fcc054;
}

.switch-button.stripe-mode .checkbox:checked~.layer {
    background-color: #78b950;
}

/*** check switch button END ***/

.social-links-fieldset .input-field:first-of-type {
    margin-bottom: 20px;
}

/** Tree structure */
ul.tree_view {
    padding: 0;
}

ul.tree_view li.tree_view_sub {
    padding-left: 25px;
    position: relative;
}

ul.tree_view ul {
    padding-left: 20px;
}

span.open-tree {
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    text-align: center;
    left: 0;
    top: 0px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

/** Free Products page **/
.freeproduct-fieldset label {
    display: inline-block;
    margin: 0 0 6px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.freeproduct-fieldset {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;
}

.form-field {
    display: flex;
    flex-wrap: wrap;
}

.input-field {
    padding: 0 6px;
    margin: 6px 0;
    width: 100%;
    position: relative;
}

.rel {
    position: relative;
}

.input-field .labeltxt,
.input-field label {
    display: inline-block;
    padding: 0 0 8px;
    font-size: 16px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.input-two-col {
    width: 50%;
}

.input-three-col {
    width: 33.33%;
}

.input-four-col {
    width: 25%;
}

.up-data-file {
    padding: 12px 10px;
    margin: 0px 0 6px;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    background: #fbfbfb;
}

.up-data-file a {
    color: #000;
}

.up-data-file a:hover {
    color: #dd3144;
}

/* Filter -- START */

.table-filter {
    display: flex;
    flex-flow: row wrap;
    margin: 0 0 10px;
    justify-content: space-between;
}

.add-holidaybtn button.addbtn {
    margin: 0;
}

.table-search {
    position: relative;
    flex: 1 1 auto;
    margin: 0 10px 0 0;
    width: 480px;
}

.orders-drop-left .table-search {
    width: 360px;
}

.table-search .form-control {
    padding-left: 15px;
    margin: 0 0 0 0;
    font-size: 14px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #323232;
}

.table-search .form-control::placeholder {
    color: #323232;
}

.table-search::before {
    content: "\f002";
    content: '';
    font-family: FontAwesome;
    position: absolute;
    font-size: 20px;
    color: #ec2f45;
    top: 50%;
    transform: translateY(-50%);
    left: 6px;
    z-index: 2;
}

.table-select {
    flex: 1 1 auto;
    max-width: 200px;
}

.table-select .css-b62m3t-container .css-1s2u09g-control {}

.table-select .css-b62m3t-container input {
    height: auto;
}

.table-select .css-b62m3t-container .css-1s2u09g-control:hover {
    border-color: transparent;
}

.table-search-reset {
    display: flex;
}

.products-search-left .table-search-reset {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.vendors-table-wrap .table-search {
    width: 300px;
}

/** search order bg **/
.search-orders {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 20px;
    background: #d3d0c9;
    padding: 20px;
    border-radius: 10px;
}

.search-order-left {
    width: 100%;
}

.search-orders .button.reload-btn {
    float: right;
    padding: 18px 20px;
}

.search-orders input[type="text"],
.search-orders input.search-ord,
.search-orders select.search-ord {
    margin: 0;
    font-size: 14px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #323232;
    height: 52px;
    padding-right: 60px;
    box-shadow: 1px 1px 6px rgba(60, 57, 46, 0.1);
}

.search-orders.orders-search-bx input[type="text"],
.search-orders.orders-search-bx input.search-ord,
.search-orders.orders-search-bx select.search-ord {
    width: 100%;
    box-shadow: none;
}

.search-orders input[type="text"]::placeholder,
.search-orders input.search-ord::placeholder {
    color: #323232;
}

.search-orders .button,
.search-orders input[type="button"],
.search-orders .button.resetbtn,
.search-orders button.resetbtn,
.search-orders input[type="button"].resetbtn {
    margin: 0 0 0 10px;
    padding: 12px 20px 12px;
    height: 52px;
}

.search-orders button.button {
    margin-left: -64px !important;
}

.search-orders .button i {
    line-height: 0.8;
}

.orders-dropdown {
    display: flex;
    flex-wrap: wrap;
    padding-right: 8px;
}

.orders-dropdown select {
    height: 52px;
    margin: 0;
    padding: 6px 24px 6px 12px;
    width: 180px;
    background-image: url(../images/down.png);
    background-repeat: no-repeat;
    background-position: center right 10px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font-size: 14px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    position: relative;
}

.orders-dropdown select option {
    padding: 6px 10px;
    font-size: 15px;
    font-family: 'Proxima-Nova-Bold';
}

.resetbtn {
    margin: 0px;
    padding: 10px 20px;
}

.submitbtn {
    margin: 0px !important;
    padding: 10px 20px !important;
}

/* Filter -- END */

.form-container-sm {
    width: 100%;
    max-width: 800px;
}

.form-container-xs {
    width: 100%;
    max-width: 680px;
}

/* Pagination -- START */
.table_overflow .rdt_Pagination {
    /* justify-content: center; */
    border-color: transparent;
    margin: 10px 0 5px;
}

.table_overflow .rdt_Pagination .kJrhuj,
.table_overflow .rdt_Pagination select,
.chart-container .rdt_Pagination select {
    color: #000;
    padding: 5px 8px 5px 5px;
    border: 1px solid #afafaf;
    border-radius: 4px;
    margin: 0 0 0 0;
}

.table_overflow .rdt_Pagination .gnaqej svg,
.table_overflow .rdt_Pagination select+svg,
.chart-container .rdt_Pagination select+svg {
    top: 49%;
    transform: translateY(-50%);
    right: -13px;
}

.table_overflow .gnaqej {
    margin-top: 0px;
}

.rdt_Pagination .OHwJB,
.rdt_Pagination span {
    color: #000;
    font-size: 14px;
    margin: 0 10px 0 20px;
}

.table_overflow .jMMJgk {}

.table_overflow .rdt_Pagination button {
    cursor: pointer;
    margin: 0 5px 0;
    border: 1px solid #ed7c88;
    box-shadow: -1px -2px 2px -1px rgb(0 0 0 / 15%);
}

.table_overflow .rdt_Pagination button:hover {
    color: #FFF;
    background: #ec2f45;
}

.table_overflow .rdt_Pagination button:hover svg {
    fill: #FFF;
}

.table_overflow .rdt_Pagination button[aria-disabled="true"]:hover {
    cursor: not-allowed;
    background: rgb(236 47 69 / 50%);
}

.table_overflow .rdt_Pagination button[aria-disabled="true"]:hover svg {
    fill: rgb(0 0 0 / 50%);
}

.rdt_Pagination select option:empty {
    display: none;
}

/* Pagination -- END */

/* Filter Table -- END */

.pro-form {
    margin: 25px 0;
}

.pro-row {
    margin: 0 0 10px;
}

.pro-row label {
    display: inline-block;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    margin: 0 0 6px;
}

.pro-row label span {
    color: #f00;
}

.pro-row .form-control {}

.pro-row select.form-control {
    padding: 5px 10px;
}

.pro-row .button {
    margin-right: 15px;
}

.dark-btn {
    background: #000;
}

.dark-btn:hover {
    background: #ec2f45;
}

.toggle-btn {
    display: inline-block;
    margin: 15px 0;
}

/* Accordion -- START */

.cart-accord {
    margin: 50px 0;
}

.accordion {
    width: 100%;
    max-width: 100%;
}

.panel {
    display: block;
    background-color: #f0ebe1;
    margin: 15px 0 15px;
}

.panel__label {
    position: relative;
    display: block;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    color: #000;
    padding: 15px 60px 15px 15px;
    font-weight: 500;
    font-size: 17px;
    font-family: inherit;
    transition: color 0.2s linear;
    cursor: pointer;
    margin: 0 0 0;
}

.panel__label:focus {
    outline: none;
}

.panel__label:after,
.panel__label:before {
    content: '';
    position: absolute;
    right: 25px;
    top: 50%;
    width: 22px;
    height: 2px;
    margin-top: -2px;
    background-color: #000;
}

.panel__label:hover::after,
.panel__label:hover::before {
    background-color: #fff;
}

.panel__label:before {
    transform: rotate(-90deg);
    transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.panel[aria-expanded='true'] .panel__content {
    opacity: 1;
}

.panel[aria-expanded='true'] .panel__label {
    font-weight: bold;
    color: #000;
}

.panel[aria-expanded='true'] .panel__label:hover {
    color: #fff;
}

.panel[aria-expanded='true'] .panel__label:before {
    transform: rotate(0deg);
}

.panel__inner {
    overflow: hidden;
    will-change: height;
    transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.panel__content {
    margin: 15px;
    font-size: 14px;
    color: #756658;
    opacity: 0;
    transition: opacity 0.3s linear 0.18s;
}

.panel:not(:last-child) {
    margin-bottom: 3px;
}

/* Accordion -- END */

.modal .pro-form {
    display: flex;
    flex-flow: row wrap;
}

.modal .pro-row {
    flex: 1 1 auto;
    max-width: 48%;
    width: 100%;
    margin: 0 auto;
}

.modal .pro-row.flx-bottom {
    max-width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.checkout-table .acc-btn {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.checkout-table table tbody tr td {
    position: relative;
    font-family: 'Proxima Nova Rg';
    font-size: 16px;
    color: #2B2E34;
    padding: 12px 5px 12px 10px;
    vertical-align: middle;
}

.checkout-table table tbody tr td.prt-title .prtf-flx {
    display: flex;
    flex-flow: row nowrap;
}

.checkout-table table tbody tr td.prt-title .prtf-flx .prt-txt:hover {
    cursor: pointer;
    color: #ec2f45;
}

.checkout-table table tbody tr td.prt-title .prtf-flx.prtf-point,
.checkout-table table tbody tr td.prt-title:hover .prtf-flx.prtf-point {
    pointer-events: none;
}

.checkout-table table tbody tr td.prt-title:hover .prtf-flx.prtf-point .prt-txt {
    cursor: none;
    color: #000;
}

.prt-ico-flx {
    position: relative;
    display: flex;
    padding-top: 7px;
    margin-top: 7px;
}

.prt-ico-flx:empty {
    display: none;
}

.prt-ico-flx::after {
    content: "";
    position: absolute;
    top: 0;
    width: 50%;
    height: 1px;
    background: #ddd;
}

.checkout-table table tbody tr td .prt-ico {
    margin-left: 5px;
}

.checkout-table table tbody tr td .prt-ico:first-child {
    margin-left: 0px;
}

.checkout-table table tbody tr td.table-tol {
    width: 130px;
}

.my-order-details-pop table thead tr td:nth-of-type(3),
.my-order-details-pop table tbody tr td:nth-of-type(3) {
    text-align: center;
}

.checkout-table table tbody tr td .prt-img .prt-ovl {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 5px;
}

.checkout-table table tbody tr td .prt-img .prt-ovl::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background: rgb(0 0 0 / 0%);
    transition: all .5s ease-in-out;
}

.checkout-table table tbody tr td .prt-img:hover .prt-ovl::before {
    background: rgb(0 0 0 / 25%);
}

.checkout-table table tbody tr td .prt-img img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    border-radius: 50%;
}

/*** 02-02-2023 Start ***/
.input-group {
    display: flex;
    flex-wrap: wrap;
}

.custom-file {
    width: 49.4%;
    margin: 0px 10px 10px 0px;
}

.custom-file:nth-child(2n+2) {
    margin-right: 0px;
}

.custom-file input[type="file"] {
    height: 50px;
    margin: 8px 0;
    font-size: 14px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #323232;
}

/*** 02-02-2023 END ***/

.rdt_Table .fwKvpK {
    padding: 20px 15px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #ec2f45;
}

.admin-account-table .rdt_Table div:first-child,
.admin-account-table .rdt_Table * {
    white-space: pre-wrap;
    font-size: 15px;
}

.admin-account-table .rdt_Table .rdt_TableHead .rdt_TableCol {
    padding: 6px 8px 6px;
}

.admin-account-table .rdt_Table .rdt_TableBody .rdt_TableCell {
    padding: 6px 8px 6px;
}

.flex-title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 10px;
}

.flex-title h2,
.flex-title h1 {
    margin: 0;
}

.prod-import-btn button {
    margin: 0 0 0 10px;
}

.custom_check-box.col-2,
.custom_check-box.col-3,
.custom_check-box.col-4 {
    display: flex;
    flex-wrap: wrap;
}

.custom_check-box.col-2 .check-box {
    width: 50%;
}

.custom_check-box.col-3 .check-box {
    width: 33.33%;
}

.custom_check-box.col-4 .check-box {
    width: 25%;
}


.input-field .check-box label {
    font-size: 15px;
}

/** scroll to top **/
.scrolltop {
    position: fixed;
    right: 30px;
    bottom: 90px;
    transition: all 0.4s ease;
    visibility: hidden;
    opacity: 0;
    z-index: 99;
}

.scrolltop.show {
    opacity: 1;
    visibility: visible;
}

.scrolltop .disbl_href_action {
    display: block;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    text-align: center;
    background: #332e20;
    color: #FFF;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.scrolltop .disbl_href_action span {
    display: block;
    width: 100%;
    height: 100%;
    line-height: 42px;
}

.scrolltop .disbl_href_action span i {
    position: absolute;
    left: 50%;
    top: 47.5%;
    transform: translate(-50%, -50%);
}

.scrolltop .disbl_href_action span i.ars {
    display: none;
}

.scrolltop .disbl_href_action:hover {
    background: #ec2f45;
}

/** scroll End **/

/**** loading ani *****/
.spin {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-buffer,
.rdt_TableBody .rdt_TableRow.loading-buffer {
    position: relative;
    pointer-events: none;
    z-index: 2;
}

.loading-buffer::before,
.rdt_TableBody .rdt_TableRow.loading-buffer::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.9);
    z-index: 4;
}

.loading-buffer::after,
.rdt_TableBody .rdt_TableRow.loading-buffer::after {
    position: absolute;
    content: '';
    left: calc(50% - 10px);
    top: calc(50% - 10px);
    border: 3px solid #ec2f45;
    border-radius: 50%;
    border-top: 3px solid #FFF;
    border-bottom: 3px solid #ec2f45;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    z-index: 4;
}


/* Custom popup - start */
.custom-alert-pop {
    position: fixed;
    /*    display: none;*/
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999;
}

.custom-alert-pop::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: -1;
}

.custom-alert-container {
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 440px;
    max-width: 90%;
    transform: translate(-50%, -50%);
}

.custom-alert-header {
    background: #ec2f45;
    color: #FFF;
    padding: 14px 20px 14px;
    border-radius: 4px 4px 0 0;
    text-align: center;
}

.custom-alert-header h4 {
    color: #FFF;
    margin: 0;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    text-transform: uppercase;
}

.custom-alert-body {
    padding: 25px 30px 60px;
    background: #FFF;
    text-align: center;
    min-height: 120px;
    border-radius: 0 0 4px 4px;
}

.custom-alert-body p {
    margin: 0 0 10px;
}

.custom-alert-body .alert-buttons {
    text-align: center;
    padding: 15px 0 0;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 15px;
}

.alert-buttons a.button {
    padding: 8px 24px 8px;
    border-radius: 3px;
    margin: 5px 5px 0;
}

/* Custom popup- END */

.login-page footer {
    position: relative;
}

.btnfield {
    width: 100%;
    padding: 0 5px;
}

.input-field.col-1 {
    width: 100%;
}

.input-field.col-2 {
    width: 50%;
}

.input-field.col-3 {
    width: 33.33%;
}

.input-field.col-4 {
    width: 25%;
}

.full-width {
    width: 100% !important;
}

.editform.freeprod-form .custom_check-box .check-box,
.editform.freeprod-forms .custom_check-box .check-box {
    width: 50%;
    display: inline-block;
}

.switch-button-holder {
    display: block;
    width: auto;
    padding: 15px 40px 25px 30px;
    border: 1px solid #e5e5e5;
    border-radius: 12px;
}

.switch-button-holder label {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-size: 22px;
}

/**** accordion ****/
.accordion-box {
    padding: 20px 0 0px;
}

.accordion-header {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 16px 20px 18px;
    background: #f2f2f2;
    border-radius: 6px;
    transition: all 0.4s ease;
    cursor: pointer;
}

.accordion-header.show {
    background: #ec2f45;
}

.accordion-header h2 {
    margin: 0;
    font-size: 20px;
}

.accordion-header.show h2 {
    color: #FFF;
}

.accordion-header span.show-accordion {
    display: block;
    width: 28px;
    height: 28px;
    border: 2px solid #323232;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    top: 12px;
}

.accordion-header.show span.show-accordion {
    border-color: #FFF;
    color: #FFF;
}

.accordion-header span.show-accordion::after {
    position: absolute;
    content: "+";
    font-size: 28px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.4s ease;
}

.accordion-header.show span.show-accordion::after {
    content: "-";
    top: 45%;
}

.accordion-body {
    padding: 20px 5px;
}

/**** accordion END ****/


/**** Select - drop Filter option *****/
.select-drop {
    margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 430px;
    position: relative;
    justify-content: flex-end;
}

.select-drop select {
    margin: 0;
    height: 50px;
    padding: 6px 12px;
    /* background-image: url(../images/down.png); */
    background-repeat: no-repeat;
    background-position: center right 8px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.select-drop select::-ms-expand {
    display: none;
}

.select-drop button.button {
    margin-left: 0px !important;
}

.orders-dropdown.vendor-select-drop {
    max-width: calc(50% - 80px);
    padding-right: 0;
    justify-content: flex-end;
}

.orders-dropdown.prod-select-drop select.handle-srch,
.orders-dropdown.vendor-select-drop select.handle-srch {
    height: 52px;
    max-width: fit-content;
    padding-right: 26px;
}

.orders-dropdown.vendor-select-drop>select.handle-srch,
.orders-dropdown.vendor-select-drop .dropdown-container {
    margin-right: 10px;
    max-width: fit-content;
}

.orders-dropdown.vendor-select-drop .dropdown-container {
    margin-right: 10px;
    max-width: 125px;
}

.orders-dropdown.vendor-select-drop>select.handle-srch:last-of-type {
    margin-right: 0;
}

.select-drop a.filter-btn {
    padding: 16px 20px 16px;
    width: 170px;
    margin: 0px 0 0px;
    font-size: 16px;
    line-height: 1.3;
    text-align: left;
    background: #FFF;
    color: #222;
    position: relative;
    box-shadow: 0px 4px 9px 0px rgba(32, 32, 32, 0.08);
}

.select-drop a.filter-btn:hover,
.select-drop a.filter-btn.active {
    background: #ec2f45;
    color: #FFF;
}

.select-drop a.filter-btn.button .filter-icon {
    position: absolute;
    right: 20px;
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    border-left: 1px solid #b4b4b4;
    padding-left: 10px;
}

.select-drop a.filter-btn.button.active .filter-icon {
    border-left: 1px solid #e4e4e4;
}

.filter-bx {
    position: absolute;
    padding: 20px 15px 75px;
    background: #FFF;
    border-radius: 6px;
    right: 0px;
    width: 100%;
    top: 62px;
    display: none;
    z-index: 999;
    box-shadow: 0px 4px 9px 0px rgba(32, 12, 2, 0.18);
}

.filter-bx.show {
    display: block;
    -webkit-animation: slide_up ease-out 0.4s 0s;
    animation: slide_up ease-out 0.4s 0s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    width: 380px;
}

.filter-bx::before {
    position: absolute;
    content: '';
    width: 12px;
    height: 12px;
    background: #FFF;
    right: 20px;
    top: -6px;
    transform: rotate(45deg);
}

.filter-btns {
    display: block;
    width: 100%;
    position: absolute;
    bottom: 10px;
    padding: 10px 0 5px;
    border-top: 1px solid #d4d4d4;
    left: 0;
    text-align: center;
    z-index: 2;
}

.filter-btns button.button {
    margin: 5px 5px 5px;
    height: auto;
    font-size: 14px;
    padding: 8px 10px 8px;
    min-width: 80px;
}

.filter-btns button.button.clear-btn {
    background: #565656;
}

.filter-btns button.button.clear-btn:hover {
    background: #000;
}

.no-filter-list .filter-bx.show {
    padding: 10px;
}

.filter-bx .no-filter {
    text-align: center;
    padding: 20px 0;
    color: #ec2f45;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.filter-options {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
}

.filter-options::-webkit-scrollbar {
    width: 6px;
}

.filter-options::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.filter-options::-webkit-scrollbar-thumb {
    background-color: #d6d6d6;
}

.filter-options a.filter-val {
    padding: 8px 14px 8px;
    width: calc(50% - 8px);
    margin: 3px 4px 6px;
    background: #f8f8f8;
    border: 2px solid #f4f4f4;
    color: #222;
    border-radius: 22px;
    font-size: 14px;
    position: relative;
    overflow: hidden;
}

.filter-options a.filter-val:hover {
    background: #f2f2f2;
    color: #222;
}

.filter-options a.filter-val.active {
    color: #ec2f45;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    border-color: #ef6474;
}

.filter-options a.filter-val span.remove-filter {
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    right: -10px;
    visibility: hidden;
    opacity: 0;
    top: 8px;
    transform: rotate(45deg);
    transition: all 0.4s ease;
    z-index: 2;
}

.filter-options a.filter-val.active:hover span.remove-filter {
    right: 10px;
    visibility: visible;
    opacity: 1;
}

.filter-options a.filter-val span.remove-filter::after,
.filter-options a.filter-val span.remove-filter::before {
    position: absolute;
    content: '';
    background: #ec2f45;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.filter-options a.filter-val span.remove-filter::before {
    width: 2px;
    height: 100%;
}

.filter-options a.filter-val span.remove-filter::after {
    width: 100%;
    height: 2px;
}

.filter-option-mob .select-drop {
    display: none;
}

@-webkit-keyframes slide_up {
    0% {
        -webkit-transform: translateY(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes slide_up {
    0% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        opacity: 1;
    }
}

.free-prod-wrap .checkout-table table tbody tr:last-child {
    border-bottom: none;
}

.free-prod-wrap .info-bdy.offer_products {
    position: sticky;
    bottom: 20px;
    padding: 15px 0 0px;
    background: #FFF;
    border-top: 1px solid #e5e5e5;
}

.free-prod-wrap .info-bdy.offer_products::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 20px;
    top: 100%;
    background: inherit;
}

.free-prod-wrap .info-bdy.offer_products .btn-item button {
    margin: 0;
}

.checkout-table table tbody tr td.prt-title .prtf-flx .prt-txt h4 span.color-red {
    display: inline-block;
    font-size: 1.325em;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}


/*** filter new show ***/
.filter-field-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 20px;
}

.filter-field-wrap h3 {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    margin: 0 0 10px;
}

.filter-show-bx {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.filter-show-bx .filters {
    padding: 8px 26px 8px 12px;
    background: #f2f2f2;
    border-radius: 6px;
    margin: 4px 8px 4px 0px;
    font-size: 13px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    position: relative;
    cursor: pointer;
}

.filter-show-bx .filters a.filter-x {
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    right: 8px;
    top: 9px;
    transform: rotate(45deg);
    transition: all 0.4s ease;
    z-index: 2;
}

.filter-show-bx .filters a.filter-x::after,
.filter-show-bx .filters a.filter-x::before {
    position: absolute;
    content: '';
    background: #000;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.filter-show-bx .filters a.filter-x::before {
    width: 2px;
    height: 100%;
}

.filter-show-bx .filters a.filter-x::after {
    width: 100%;
    height: 2px;
}

.filter-show-bx .filters.clr-all {
    padding: 8px 12px 8px 12px;
    background: #ffcfcf;
}

.filter-show-bx .filters:first-of-type {
    margin-left: 0px;
}

.filter-show-bx .filters:last-of-type {
    margin-right: 0px;
}

.payment-opt {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0 10px;
}

.payment-opt .pay-accept {
    padding: 7px 10px 5px;
    border: 2px solid #dfd9d9;
    border-radius: 6px;
    position: relative;
}

.payment-opt .pay-accept::before {
    position: absolute;
    content: 'We Accept';
    left: 50%;
    top: -8px;
    transform: translateX(-50%);
    background: #FFF;
    font-size: 12px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    padding: 2px 6px;
}

.pay-accept img.pay-meth-img {
    max-width: 150px;
}



/*** Toottip css ****/
/* title-tip down */
.title-tooltip[data-title]:hover {
    position: relative;
    display: inline-block;
}

.title-tooltip[data-title]:hover:before {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 99999;
    content: attr(data-title);
    margin-top: 0.5em;
    padding: 0.5em;
    width: 120px;
    font-size: 12px;
    overflow: hidden;
    word-wrap: break-word;
    text-transform: initial;
    color: #FFF;
    text-align: center;
    background-color: #222;
    box-sizing: border-box;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.title-tooltip[data-title]:hover:after {
    position: absolute;
    top: calc(100% - 5px);
    left: 50%;
    z-index: 99999;
    content: '';
    margin-left: -8px;
    width: 0;
    height: 0;
    border: 6px dashed transparent;
    border-bottom: 6px solid #222;
    font-size: inherit;
}

.title-top.title-tooltip[data-title]:hover:after {
    bottom: calc(100% - 0px);
    top: auto;
    border-top: 6px solid #222;
    border-bottom: none;
}

.title-top.title-tooltip[data-title]:hover:before {
    bottom: calc(100% + 6px);
    top: auto;
}

/* title-tip-up */
.title-up[data-title]:hover:before {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.5em;
}

.title-up[data-title]:hover:after {
    top: calc(0% - 8px);
    right: calc(100% + 5px);
    border: 8px dashed transparent;
    border-top: 8px solid #222;
    margin-left: -12px;
}

/* title-tip-right */
.title-right[data-title]:hover:before {
    top: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.5em;
}

.title-right[data-title]:hover:after {
    top: 50%;
    left: 100%;
    margin-left: 0;
    margin-top: -0.125em;
    border: 0.25em dashed transparent;
    border-right: 0.25em solid #222;
}

/* title-tip-left */
.title-left[data-title]:hover:before {
    top: auto;
    left: auto;
    right: 100%;
    margin-top: 0;
    margin-right: 0.5em;
}

.title-left[data-title]:hover:after {
    top: 50%;
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-top: -0.125em;
    border: 0.25em dashed transparent;
    border-left: 0.25em solid #222;
}

/*** Tootlpip END ****/

/************* Login form forget password text style*****************/
.forget-pass-field a {
    color: #323232;
    font-size: 13px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.forget-pass-field a:hover {
    color: #dd3144;
}

.forget-pass-field {
    border-top: 1px solid #e5e5e5;
    margin: 10px 0 0;
    padding: 15px 0 0;
}

/************* Login form forget password text style*****************/

/************** Reset Password page style *******************/
.login-form>p {
    font-size: 15px;
    padding: 0 0 15px;
}

.login .form form.login-form input {
    margin: 8px 0 10px;
}

/************** Reset Password page style *******************/


/***** Back button style center*******/
.login .back-to-cat {
    margin: 0 0 15px;
    text-align: center;
}

.back-to-cat a.back-btn {
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    display: inline-block;
    font-size: 15px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    line-height: 1.5;
    padding: 5px 12px 5px 25px;
    position: relative;
    color: #332e20;
}

.back-to-cat a.back-btn i {
    position: relative;
    left: -6px;
}

.back-to-cat a.back-btn:hover {
    border-color: #dd3144;
    color: #dd3144;
}

.ord-grand-total .order_remarks {
    font-weight: 400;
}

/*******************/
/**********added by k2b vivek *******/
.filter-bx {
    position: absolute;
    padding: 20px 15px 75px;
    background: #FFF;
    border-radius: 6px;
    right: 0px;
    width: 100%;
    top: 62px;
    display: none;
    z-index: 999;
    box-shadow: 0px 4px 9px 0px rgba(32, 12, 2, 0.18);
}

.filter-bx.show {
    display: block;
    animation: slide_up ease-out 0.4s 0s;
    animation-fill-mode: both;
    width: 380px;
}

.select-drop {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    position: relative;
    width: auto;
}

.orders-search-bx .select-drop {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    position: relative;
    flex-basis: calc(100% - 680px);
}

.filter-date-range {
    position: relative;
    padding: 10px 0 15px;
    z-index: 3;
}

.search-orders .fltr-dt-range input[type="text"] {
    width: 100%;
    height: 40px;
    background: transparent;
    box-shadow: 1px 1px 6px rgba(60, 60, 60, 0.03);
}

.fltr-dt-range {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px 0 0;
    display: none;
}

.fltr-dt-range.show {
    display: flex !important;
    display: block;
}

.fltr-dt-range .fltr--date {
    width: 47.5%;
    position: relative;
}

.fltr-dt-range .fltr--date .react-datepicker-wrapper {
    width: 100%;
}

.fltr-dt-range .fltr--date span.date-icon {
    position: absolute;
    right: 8px;
    bottom: 6px;
    z-index: -1;
}

.fltr-dt-range .fltr--date span {
    display: block;
    margin: 0 0 6px;
    font-size: 14px;
}

.fltr-dt-wrap label {
    display: block;
    margin: 0 0 8px;
    font-size: 13px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.select-drop a.filter-btn {
    padding: 16px 20px 16px;
    width: 170px;
    margin: 0px 0 0px;
    font-size: 16px;
    line-height: 1.3;
    text-align: left;
    background: #FFF;
    color: #222;
    position: relative;
    box-shadow: 0px 4px 9px 0px rgba(32, 32, 32, 0.08);
}

.select-drop a.filter-btn:hover,
.select-drop a.filter-btn.active {
    background: #ec2f45;
    color: #FFF;
}

.filter-btns {
    display: block;
    width: 100%;
    position: absolute;
    bottom: 10px;
    padding: 10px 0 5px;
    border-top: 1px solid #d4d4d4;
    left: 0;
    text-align: center;
    z-index: 2;
}

.filter-btns button.button {
    margin: 5px 5px 5px;
    height: auto;
    font-size: 14px;
    padding: 8px 10px 8px;
    min-width: 80px;
}

.filter-btns button.button.clear-btn {
    background: #565656;
}

.filter-bx::before {
    position: absolute;
    content: '';
    width: 12px;
    height: 12px;
    background: #FFF;
    right: 20px;
    top: -6px;
    transform: rotate(45deg);
}

.search-orders.orders-search-bx .filter-date-range .fltr-dt-wrap input[type="text"] {
    height: 32px;
}

@keyframes slide_up {
    from {
        transform: translateY(60%);
    }

    to {
        transform: translateY(0);
    }
}

.filter-options {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
}

.filter-options a.filter-val {
    padding: 8px 14px 8px;
    width: calc(50% - 8px);
    margin: 3px 4px 6px;
    background: #f8f8f8;
    border: 2px solid #f4f4f4;
    color: #222;
    border-radius: 22px;
    font-size: 14px;
    position: relative;
    overflow: hidden;
}

.filter-options a.filter-val.active {
    color: #ec2f45;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    border-color: #ef6474;
}

.filter-options a.filter-val span.remove-filter {
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    right: -10px;
    visibility: hidden;
    opacity: 0;
    top: 8px;
    transform: rotate(45deg);
    transition: all 0.4s ease;
    z-index: 2;
}

.filter-options a.filter-val.active {
    color: #ec2f45;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    border-color: #ef6474;
}

.checkout-table .table_overflow .rdt_Table button {
    padding: 6px 12px 6px;
}

.alert-buttons button {
    border-radius: 3px;
    margin: 5px 5px 0;
    padding: 8px 24px;
}

.custom-alert-wrap .custom-alert-header {
    background: #544a2c;
}

/**********added by k2b vivek *******/
/*** Admin change password ***/
.vendor-details.change-pwd .input-field {
    position: relative;
}

.admin-order.account-total-records {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    margin-bottom: 14px;
}

.account-total-records .ord-TotalAmt {
    display: inline-block;
    padding-left: 10px;
    margin-left: 10px;
    border-left: 1px solid #969696;
}

.vendor-details.change-pwd .input-field span.icon-eye,
.input-field span.icon-eye {
    cursor: pointer;
    position: absolute;
    right: 7px;
    bottom: 11px;
    width: 38px;
    height: 40px;
    background: #e5e5e5;
    text-align: center;
    line-height: 40px;
    border-radius: 0 5px 5px 0;
    font-size: 13px;
    color: #222;
}

.filter-count {
    margin-left: 5px;
}

.admin-account-table .rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="2"],
.admin-account-table .rdt_Table .rdt_TableBody .rdt_TableCell[data-column-id="2"] {
    min-width: 220px;
}

.admin-account-table .rdt_Table .rdt_TableBody .rdt_TableCell[data-column-id="3"],
.admin-account-table .rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="3"] {
    max-width: 80px;
}

.admin-account-table .rdt_Table .rdt_TableBody .rdt_TableCell[data-column-id="5"],
.admin-account-table .rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="5"],
.admin-account-table .rdt_Table .rdt_TableBody .rdt_TableCell[data-column-id="6"],
.admin-account-table .rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="6"] {
    min-width: 165px;
}

.admin-account-table .rdt_Table .rdt_TableBody .rdt_TableCell[data-column-id="7"],
.admin-account-table .rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="7"] {
    max-width: 80px;
    justify-content: center;
}

.orders-drop-left {
    display: flex;
    flex-wrap: wrap;
}

.products-search-left {
    display: flex;
    flex-wrap: wrap;
}

/*** Admin change password END ***/
.custom-react-select {
    z-index: 5;
}

/* 
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden; 
} */

.pdf-container {
    width: 100%;
    height: 100vh;
    /* Full viewport height */
}

.pdf-container iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5em;
}

.dev-portal-tag {
    width: 100%;
    padding: 6px 0 6px;
    position: fixed;
    top: 0;
    left: 0;
    background: #d42929;
    text-align: center;
    color: #FFF;
    z-index: 99999;
    font-size: 13px;
}

.unauthorized-wrap {
    padding: 20px;
    margin: 50px 0 0;
    display: flex;
    background: #f7f7ef;
    border: 1px solid #e1d9d9;
    border-radius: 6px;
}

.unauthorized-wrap p,
.reset-pass-tokenerr p {
    display: flex;
    align-items: center;
}

.unauthorized-wrap p i,
.reset-pass-tokenerr p i {
    color: #e35e3f;
    display: inline-flex;
    margin-right: 10px;
    font-size: 1.5em;
}

.reset-pass-container {
    padding: 60px 0 50px;
    text-align: center;
}

.reset-pass-tokenerr {
    padding: 20px;
    margin: 10px 0 30px;
    display: inline-flex;
    background: #f7f7ef;
    border: 1px solid #e1d9d9;
    border-radius: 6px;
}

.reset-pass-container a.back-btn.button {
    padding: 12px 20px 12px 20px;
    color: #FFF;
}

/**** change password legend ****/
.chg-pwd-lgd {
    width: calc(100% - 12px);
    max-width: 220px;
    display: flex;
    border-radius: 5px;
    position: absolute;
    right: 15px;
    top: calc(100% - 10px);
    background: #f4f4f4;
    padding: 10px 10px;
    z-index: 99;
    /* display:none; */
}

.chg-pwd-lgd::before {
    position: absolute;
    content: "";
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #f4f4f4 transparent;
    top: -16px;
    right: 10px;
}

.chg-pwd-list {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
}

.chg-pwd-list li {
    font-size: 13px;
    padding: 2px 8px 2px 28px;
    position: relative;
    display: block;
    border-radius: 4px;
    text-align: left;
    color: #222;
    margin: 1px 4px 4px 0;
}

.chg-pwd-list li.unchecked {
    color: #dd5d6c;
    border-color: #dd5d6c;
}

.chg-pwd-list li.checked {
    color: #2bb930;
    border-color: #2bb930;
}

.chg-pwd-list li.checked::before {
    position: absolute;
    content: "\f058";
    font: normal normal normal 12px / 1 FontAwesome;
    margin-left: -20px;
}

.chg-pwd-list li.unchecked::before {
    position: absolute;
    content: "\f00d";
    font: normal normal normal 12px / 1 FontAwesome;
    margin-left: -20px;
}

.refresh-btn i {
    font-size: 22px;
}

/*** ***/
.vendor-details.vendor-table-view {
    display: block;
}

.order-top .vendor-details.vendor-table-view p {
    margin: 0 0 -1px;
    padding: 0px;
    border: 1px solid #d2d2d2;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
}

.vendor-details.vendor-table-view p .labeltxt {
    display: inline-block;
    width: 200px;
    padding: 6px 10px;
    border-right: 1px solid #d2d2d2;
}

.vendor-details.vendor-table-view p .detailtxt {
    width: calc(100% - 205px);
    padding: 6px 10px;
}

.vendor-table-view table.vendor-table {
    border: 1px solid #e4e4e4;
}

.vendor-table-view table.vendor-table tr td {
    padding: 10px 14px;
}
.vendor-table-view table.vendor-table tr th {
    padding: 10px 14px;
    font-family: 'Proxima-Nova-Bold';
}

.vendor-table-view table.vendor-table td:first-of-type {
    font-weight: bold;
    min-width: 180px;
}

.vendor-table-view table.vendor-table tr:nth-of-type(even) {
    background: #f6f6f6;
}

.order-pop .order-top .bottom.product-details {
    padding: 10px 0;
}
.vendor-table-view.product-details table.vendor-table td{
    min-width: 85px;
}
.vendor-table-view.product-details table.vendor-table td:last-of-type,
.vendor-table-view.product-details table.vendor-table tr th:last-of-type {
    text-align: right;
    font-variant-numeric: tabular-nums;
}
.vendor-table-view.product-details table.vendor-table td:first-of-type {
    font-weight: normal;
    min-width: initial;
}
.vendor-table-view table.vendor-table.dtp-price-table td:last-of-type,
.vendor-table-view table.vendor-table.dtp-price-table tr th:last-of-type {
    text-align: right;
    font-variant-numeric: tabular-nums;
}
.vendor-table-view.product-details table.vendor-table td:last-of-type:first-of-type{
    text-align: left;
}

a.refresh-btn i {
    color: #2b2e34;
}

a.refresh-btn:hover i {
    color: #dd3144;
}

.order-notes .input-field {
    padding: 0;
}

.my-order-details-pop .checkout-table table tbody tr td.data-product-hide {
    min-width: 360px;
}

.no-records-found {
    padding: 10px 20px;
    background: #f64257;
    border-radius: 6px;
    color: #FFF;
    margin: 20px auto;
    max-width: 640px;
}

.products-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol[data-column-id="6"],
.products-table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="6"] {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vendor-list-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol[data-column-id="2"],
.vendor-list-table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="2"] {
    min-width: 100px;
    max-width: 100px;
}

.vendor-list-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol[data-column-id="4"],
.vendor-list-table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="4"] {
    justify-content: center;
    text-align: center;
    min-width: 110px;
    max-width: 110px;
}

.vendor-list-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol[data-column-id="5"],
.vendor-list-table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="5"] {
    min-width: 80px;
    max-width: 80px;
}

.vendor-list-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol[data-column-id="5"],
.vendor-list-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol[data-column-id="6"],
.vendor-list-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol[data-column-id="7"] {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.vendor-list-table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="5"],
.vendor-list-table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="6"] {
    justify-content: center;
    text-align: center;
}

.vendor-list-table .rdt_TableHeadRow .rdt_TableCol[data-column-id="6"],
.vendor-list-table .rdt_TableBody .rdt_TableCell[data-column-id="6"] {
    /* min-width: 180px; */
    min-width: 275px;
    justify-content: center;
}

.new-prod-data-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
    justify-content: center;
    text-align: center;
}

.new-prod-data-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol[data-column-id="2"],
.new-prod-data-table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="2"],
.new-prod-data-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol[data-column-id="3"],
.new-prod-data-table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="3"],
.new-prod-data-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol[data-column-id="4"],
.new-prod-data-table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="4"],
.new-prod-data-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol[data-column-id="5"],
.new-prod-data-table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="5"],
.new-prod-data-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol[data-column-id="6"],
.new-prod-data-table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="6"] {
    align-items: center;
    justify-content: center;
    text-align: center;
}

.new-prod-data-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol[data-column-id="1"],
.new-prod-data-table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="1"] {
    min-width: 360px;
}

.new-prod-data-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol[data-column-id="2"],
.new-prod-data-table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="2"] {
    min-width: 140px;
    max-width: 140px;
}

.new-prod-data-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol[data-column-id="3"],
.new-prod-data-table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="3"],
.new-prod-data-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol[data-column-id="4"],
.new-prod-data-table .rdt_TableBody .rdt_TableRow .rdt_TableCell[data-column-id="4"] {
    min-width: 100px;
    max-width: 100px;
}

.order-action-tbl a.refresh-btn {
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    line-height: 1;
}

.flex-log-hdr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 10px;
}

.flex-log-hdr h4 {
    margin: 0;
}

.flex-log-hdr .log-user {
    font-size: 15px;
}

.flex-log-hdr .log-date {
    font-size: 14px;
}

.flex-log-hdr>p {
    width: 120px;
}

.hdrlog-rit {
    width: calc(100% - 120px);
    text-align: right;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}

.hdrlog-rit span i {
    font-size: 13px;
    margin-right: 6px;
}

.hdrlog-rit .log-line {
    display: inline-block;
    padding: 0 6px;
}

.pop-header-top.order-top {
    position: sticky;
    top: -1px;
    z-index: 9;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loading-message {
    color: white;
    font-size: 1.5rem;
    position: relative;
    padding-left: 15px;
}

.loading-message::after {
    position: absolute;
    content: '';
    left: calc(0% - 15px);
    top: calc(50% - 10px);
    border: 3px solid #FFF;
    border-radius: 50%;
    border-top: 3px solid transparent;
    border-bottom: 3px solid #FFF;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    z-index: 4;
}


/*** 30-07-24***/
.prod-custom-grp .custom-file {
    padding: 20px;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
}

.prod-custom-grp .custom-file:first-of-type:last-of-type {
    width: 100%;
}

.prod-custom-grp .custom-file span.color-red {
    display: block;
    width: 100%;
}

.prod-custom-grp .custom-file button {
    margin: 12px 4px;
    padding: 12px 16px 12px;
}

.prod-custom-grp .custom-file button.cancel-btn {
    background: #c4c5c6;
}

.prod-custom-grp .custom-file button.cancel-btn:hover {
    background: #000;
    color: #FFF;
}

.custom-file-choose {
    position: relative;
    width: calc(100% + 2px);
    background: #fbfbfb;
    left: -1px;
    top: -1px;
    border-radius: 6px;
    z-index: 3;
    padding: 10px;
    border: 2px dashed #d6d6d6;
}

.custom-file-choose p {
    padding: 0px;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
}

.orders-table .rdt_TableBody .rdt_TableCell[data-column-id="8"],
.orders-table .rdt_TableHeadRow .rdt_TableCol[data-column-id="8"] {
    min-width: 140px;
}

/***** product import file popup *****/
.product-import-pop {
    position: fixed;
    display: flex;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    overflow: hidden;
    outline: 0;
}

.product-import-pop-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, .65);
    z-index: 1;
}

.product-import-pop .product-import-wrap {
    margin: auto;
    width: 94%;
    max-width: 600px;
    position: relative;
    z-index: 2;
}

.close-import-pop {
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid #767676;
    top: 24px;
    right: 14px;
    z-index: 2;
    cursor: pointer;
    transition: all 0.4s ease;
}

.close-import-pop:hover {
    background: #222;
    border-color: #222;
}

.close-import-pop::after,
.close-import-pop::before {
    position: absolute;
    content: "";
    background: #565656;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

.close-import-pop::before {
    height: 60%;
    width: 2px;
}

.close-import-pop::after {
    width: 60%;
    height: 2px;
}

.close-import-pop:hover::after,
.close-import-pop:hover::before {
    background: #FFF;
}

.product-import-body {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0px;
    flex-wrap: wrap;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: .3rem;
    outline: 0;
}

.product-import-header {
    padding: 20px 30px;
    border-bottom: 1px solid #d4d4d4;
}

.product-import-header h3 {
    margin: 0;
}

.product-import-content {
    padding: 30px 30px 30px;
    max-height: calc(100vh - 200px);
    overflow: hidden;
    overflow-y: auto;
}

.product-import-content::-webkit-scrollbar {
    width: 6px;
}

.product-import-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.product-import-content::-webkit-scrollbar-thumb {
    background-color: #d6d6d6;
}

.product-import-content .custom-file-add {
    position: relative;
    width: calc(100% + 2px);
    background: #fbfbfb;
    left: -1px;
    top: -1px;
    border-radius: 6px;
    z-index: 3;
    padding: 0px;
    border: 2px dashed #d6d6d6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-file-add .custom-file {
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
    padding: 25px 10px;
    cursor: pointer;
    margin: 0;
}

.custom-file-add .custom-file span {
    font-size: 16px;
    padding: 6px 12px;
    background: #e4e4e4;
    border-radius: 4px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.custom-file-add .custom-file span.add-file {
    font-size: 13px;
    padding: 3px 8px;
    background: #d6d6d6;
    border-radius: 4px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.custom-file-add .custom-file input[type="file"] {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    appearance: none;
    z-index: 1;
    cursor: pointer;
}

.custom-file-choose {
    position: relative;
    width: calc(100% + 2px);
    background: #fbfbfb;
    left: -1px;
    top: -1px;
    border-radius: 6px;
    z-index: 3;
    padding: 20px 15px;
    border: 2px dashed #d6d6d6;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom-file-choose button {
    margin: 0;
    padding: 10px 18px;
    text-transform: initial;
}

.product-import-bottom {
    text-align: right;
    margin: 0;
    padding: 0px 30px 5px;
    border-top: 1px solid #d4d4d4;
}

.product-import-bottom button.button {
    padding: 10px 18px;
    margin-left: 10px;
    text-transform: initial;
}

.product-import-bottom button.button.cancel-btn {
    background: #ffffff;
    border: 1px solid #969696;
    color: #222;
}

.product-import-bottom button.button.cancel-btn:hover {
    background: #222;
    border: 1px solid #222;
    color: #fff;
}

.product-import-bottom button.button.upload-btn {
    background: #d4d4d4;
    color: #222;
    pointer-events: none;
}

.product-import-bottom button.button.upload-btn.active {
    pointer-events: auto;
    background: #ec2f45;
    color: #FFF;
}

.product-import-bottom button.button.upload-btn.active:hover {
    background: #222;
    color: #FFF;
}

.button-secondary {
    background-color: #f2f2f2;
    border: 1px solid #d2d2d2;
    color: #626262;
}

.product-import-content .custom-file-choose p {
    width: calc(100% - 150px);
    display: flex;
    align-items: center;
}

.file-icon {
    display: inline-block;
    margin-right: 6px;
}

.file-icon img {
    width: 23px;
    display: inline-block;
}

.file-added {
    padding: 0 0 10px;
}

.stripelog-payment-table.table_overflow .rdt_TableHeadRow .rdt_TableCol[data-column-id="1"],
.stripelog-payment-table.table_overflow .rdt_TableHeadRow .rdt_TableCol[data-column-id="2"],
.stripelog-payment-table.table_overflow .rdt_TableBody .rdt_TableCell[data-column-id="1"],
.stripelog-payment-table.table_overflow .rdt_TableBody .rdt_TableCell[data-column-id="2"] {
    max-width: 130px;
    text-align: center;
    justify-content: center;
}

.stripelog-payment-table.table_overflow .rdt_TableHeadRow .rdt_TableCol[data-column-id="3"],
.stripelog-payment-table.table_overflow .rdt_TableBody .rdt_TableCell[data-column-id="3"] {
    min-width: 280px;
    text-align: center;
    justify-content: center;
}

.stripelog-payment-table.table_overflow .rdt_TableHeadRow .rdt_TableCol[data-column-id="4"],
.stripelog-payment-table.table_overflow .rdt_TableBody .rdt_TableCell[data-column-id="4"] {
    min-width: 180px;
}

.stripelog-payment-table.table_overflow .rdt_TableHeadRow .rdt_TableCol[data-column-id="5"] {
    max-width: 120px;
    justify-content: center;
    text-align: right;
}

.stripelog-payment-table.table_overflow .rdt_TableBody .rdt_TableCell[data-column-id="5"] {
    max-width: 120px;
    justify-content: flex-end;
    text-align: right;
    padding-right: 20px;
}

.stripelog-payment-table.table_overflow .rdt_TableHeadRow .rdt_TableCol[data-column-id="6"],
.stripelog-payment-table.table_overflow .rdt_TableBody .rdt_TableCell[data-column-id="6"] {
    justify-content: center;
}

.adm-free-products .table_overflow .rdt_TableHeadRow .rdt_TableCol[data-column-id="1"],
.adm-free-products .table_overflow .rdt_TableBody .rdt_TableCell[data-column-id="1"],
.adm-free-products .table_overflow .rdt_TableHeadRow .rdt_TableCol,
.adm-free-products .table_overflow .rdt_TableBody .rdt_TableCell {
    justify-content: center;
    text-align: center;
}

.order-notes-log {
    padding: 15px 0 10px;
}

.ordernotes-log .alert-buttons.btn-field.btn-center {
    padding: 0;
}

.order-notes-log {
    padding: 15px 0 15px;
}

.ordernotes-log .loading-buffer {
    padding: 15px 0 15px;
    top: 5px;
}

/**** Editfree Product ****/
.edit-free-cat-disp {
    margin: 10px 0 20px;
    padding: 15px 15px;
    border: 1px solid #d6d6d6d6;
    border-radius: 4px;
}

.edit-free-cat-disp h5 {
    margin: 0;
}

.edit-free-cat-chks {
    padding: 20px 15px 5px;
    background: #f1f1f1;
    border-radius: 4px;
    margin: 0 0 20px;
}

.custom_check-box .edit-free-cat-chks .check-box {
    width: fit-content !important;
    margin-right: 20px;
}

.custom_check-box .edit-free-cat-chks .check-box.inline {
    display: inline-block;
}

.check-box input:disabled+label {
    cursor: not-allowed;
    color: #a6a6a6;
    text-decoration-line: line-through;
}

.check-box input:disabled+label::before {
    cursor: not-allowed;
    border-color: #a6a6a6;
}

.fth-latest-orders-alert h4 {
    font-size: 20px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.3px;
    margin: 0 0 10px;
}

.fth-latest-orders-alert p i {
    display: inline-flex;
    margin-right: 8px;
    font-size: 1.075em;
    color: #ec2f45;
}

.custom-alert-body.alert-no-header {
    min-height: auto;
    padding: 25px 30px 20px;
    background: #FFF;
    text-align: center;
    border-radius: 5px;
}

/**** ****/
.more-filter-pop .filter-accordion-grp {
    margin: 12px 0 0;
}

.more-filter-pop .filter-accordion-grp:first-of-type {
    margin: 0;
}

.product-import-bottom .filter-btns {
    position: static;
    border: none;
}

.more-filter-pop .filter-btns button.button {
    margin: 5px 5px 5px;
    height: auto;
    font-size: 14px;
    text-transform: uppercase;
    padding: 8px 10px 8px;
    min-width: 80px;
}

.free-prod-bg,
.free-prod-bg td {
    background: #fff5d1 !important;
}

.text-note {
    color: #8f7e80 !important;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.3px;
}

/**** Alerts msg ***/
.no-header .custom-alert-header {
    display: none;
}

.no-header .custom-alert-body {
    border-radius: 4px;
}

.alert-text {
    display: flex;
    max-width: fit-content;
    margin: 0px 0 10px;
    text-align: left;
    flex-wrap: wrap;
}

.alert-text .alert-icon {
    width: 32px;
    display: inline-block;
    text-align: center;
}

.alert-text .alert-txt {
    width: calc(100% - 32px);
    padding-left: 12px;
    color: #868686;
}

.alert-text .alert-txt .alert-title {
    font-size: 1.125em;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.2px;
    margin: 3px 0 6px;
    display: block;
}

.alert-text .alert-txt p:last-of-type {
    margin-bottom: 0;
}

.alert-text .alert-icon i {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: 30px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.alert-text.alert-danger .alert-icon i {
    color: #f44336 !important;
    border: 2px solid;
    border-radius: 50%;
    line-height: 22px;
    width: 32px;
    height: 32px;
}

.alert-text.alert-warning .alert-icon i {
    color: #f5c023 !important;
}

.alert-text.alert-success .alert-icon i {
    color: #4CAF50 !important;
}

.alert-text.alert-info .alert-icon i {
    color: #2196F3 !important;
}

.alert-text.alert-danger .alert-txt .alert-title {
    color: #f44336 !important;
}

.alert-text.alert-warning .alert-txt .alert-title {
    color: #f5c023 !important;
}

.alert-text.alert-success .alert-txt .alert-title {
    color: #4CAF50 !important;
}

.alert-text.alert-info .alert-txt .alert-title {
    color: #2196F3 !important;
}

.alert-text.alert-danger .alert-icon i::before {
    content: "\f00d";
    font-size: 24px;
    left: 1px;
    position: relative;
}

.alert-text.alert-warning .alert-icon i::before {
    content: "\f071";
}

.alert-text.alert-success .alert-icon i::before {
    content: "\f058";
}

.alert-text.alert-info .alert-icon i::before {
    content: "\f05a";
}

.freeproduct-fieldset.holiday-add-fieldset {
    margin: 0 0 10px;
}


/*** ***/
.freeprod-form .check-box input:checked+label:after {
    top: 2px;
}

.freeprod-form input[type="number"] {
    max-width: 65px;
}

.upld-img-file .upld-img-show {
    position: relative;
    width: 100%;
    height: 100px;
}

.upld-img-file .upld-img-show img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.upld-img-file .upld-img-show a.delete-img {
    position: absolute;
    right: 10px;
    top: 10px;
    background: #FFF;
    padding: 4px 6px;
}

.upld-pdf {
    position: relative;
    width: 120px;
    height: 80px;
    border: 1px solid #a6a6a6;
    text-align: center;
    line-height: 80px;
}

.upld-pdf a.pdf-file {
    font-size: 40px;
}

.upld-pdf a.delete {
    position: absolute !important;
    right: 0;
    top: 0px;
    line-height: 1;
    padding: 6px;
}


.flex {
    display: flex;
    flex-wrap: wrap;
}

.align-center {
    align-items: center;
}

.justify-start {
    justify-content: flex-start;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.gap-10 {
    gap: 10px;
}

.gap-15 {
    gap: 15px;
}

.gap-20 {
    gap: 20px;
}

.gap-25 {
    gap: 25px;
}

.gap-30 {
    gap: 30px;
}

.col-1 {
    width: 100% !important;
}

.col-2 {
    width: 50% !important;
}

.col-3 {
    width: 33.33% !important;
}

.col-4 {
    width: 25% !important;
}


.order-pop .order-top .bottom.vendor-details table.vendor-table,
.order-pop .order-top .bottom.vendor-details table.vendor-table tr {
    width: 100%;
    display: block;
}

.order-pop .order-top .bottom.vendor-details table.vendor-table tr td {
    word-break: break-word;
}

/*** dashboard ***/
.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    gap: 20px;
}

.flex-left {
    width: calc(50% - 10px);
    padding: 5px;
    border: 1px solid #f6f6f6;
    box-shadow: 1px 0px 16px rgba(142, 134, 171, 0.08);
    display: inline;
    place-items: start center;
    border-radius: 10px;
}

.flex-right {
    width: calc(50% - 10px);
    padding: 5px;
    border: 1px solid #f6f6f6;
    box-shadow: 1px 0px 16px rgba(142, 134, 171, 0.08);
    display: inline;
    place-items: start center;
    border-radius: 10px;
}

.flex-left.revenue-chart-wrap {
    width: calc(66.66% - 10px);
}

.flex-left.sector-chart-wrap {
    width: 100%;
}

.flex-right.orders-chart-wrap {
    width: calc(33.33% - 10px);
}

.chart-container {
    width: 100%;
    padding: 10px;
}

.chart-order-count canvas {
    max-width: 320px;
    max-height: 320px;
}

.chart-order-count {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.chart-Hdr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    padding: 0px 0 12px;
    margin: 0 0 15px;
}

.chart-Hdr h2 {
    text-align: left;
    font-size: 16px;
    margin: 0;
}

.chart-Hdr .chart-fltr {
    /* width: calc(100% - 200px);
    flex-wrap: wrap; */
    display: flex;
    justify-content: end;
    position: relative;
}

.chart-top-selling .chart-Hdr{
    justify-content: flex-start;
    gap: 10px;
}

.chart-top-selling .chart-Hdr .chart-Hdr-txt{
    width: 100%;
}
.chart-category-sales .chart-Hdr {
    justify-content: space-between;
    gap: 0px;
}
.chart-category-sales .chart-Hdr .chart-Hdr-txt {
    width: auto;
}
.chart-fltr-hldr {
    display: flex;
    gap: 10px;
}

.chart-Hdr .chart-fltr select {
    display: inline-block;
    margin: 0 0 0 10px;
    padding: 5px 26px 5px 10px;
    font-size: 14px;
    font-family: 'Proxima-Nova-Bold';
    height: auto;
    cursor: pointer;
    min-width: 150px;
    max-width: fit-content;
    position: relative;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: #f6f8fb url(../images/down.png) no-repeat center right 10px;
    border-color: #e7ecf3;
    background-size: 10px;
}

.chart-Hdr .chart-fltr select:first-of-type {
    margin: 0px;
}

.chart-Hdr .chart-fltr select option {
    cursor: pointer;
    font-size: 13px;
    font-family: 'Proxima-Nova-Bold';
}

.chart-category-sales .chart-Hdr .chart-fltr select {
    min-width: 130px;
    max-width: 145px;
}

.chart-category-sales .fltr-sub-val span.fltr-val {
    display: inline-block;
    margin: 0 2px;
}

.chart-top-selling .rdt_TableBody .rdt_TableRow:not(:last-of-type),
.chart-high-customers .rdt_TableBody .rdt_TableRow:not(:last-of-type) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.01);
}

.chart-top-selling .rdt_TableBody .rdt_TableRow:nth-child(even),
.chart-high-customers .rdt_TableBody .rdt_TableRow:nth-child(even) {
    background-color: #f6f8fb;
}

.chart-top-selling .rdt_TableHeadRow .rdt_TableCol[data-column-id="1"],
.chart-top-selling .rdt_TableBody .rdt_TableCell[data-column-id="1"] {
    min-width: 95px;
    max-width: 95px;
}

.chart-top-selling .rdt_TableHeadRow .rdt_TableCol[data-column-id="3"],
.chart-top-selling .rdt_TableBody .rdt_TableCell[data-column-id="3"] {
    min-width: 70px;
    max-width: 70px;
    justify-content: center;
    text-align: center;
}

.chart-top-selling .rdt_TableHeadRow .rdt_TableCol[data-column-id="4"],
.chart-top-selling .rdt_TableBody .rdt_TableCell[data-column-id="4"] {
    min-width: 80px;
    max-width: 80px;
    justify-content: center;
}

.chart-high-customers .rdt_TableHeadRow .rdt_TableCol[data-column-id="1"],
.chart-high-customers .rdt_TableBody .rdt_TableCell[data-column-id="1"] {
    min-width: 100px;
    max-width: 100px;
}

.chart-high-customers .rdt_TableHeadRow .rdt_TableCol[data-column-id="4"],
.chart-high-customers .rdt_TableBody .rdt_TableCell[data-column-id="4"] {
    min-width: 120px;
    max-width: 120px;
    justify-content: right;
    text-align: right;
    padding-right: 10px;
    font-variant-numeric: tabular-nums;
}

.chart-high-customers .rdt_TableHeadRow .rdt_TableCol[data-column-id="3"],
.chart-high-customers .rdt_TableBody .rdt_TableCell[data-column-id="3"] {
    min-width: 80px;
    max-width: 80px;
    justify-content: center;
    text-align: right;
    font-variant-numeric: tabular-nums;
}

.chart-customers-loged .rdt_TableHeadRow .rdt_TableCol[data-column-id="3"],
.chart-customers-loged .rdt_TableBody .rdt_TableCell[data-column-id="3"]{
    justify-content: center;
    text-align: center;
    font-variant-numeric: tabular-nums;
}

.chart-abandd-cart .rdt_TableHeadRow .rdt_TableCol[data-column-id="3"],
.chart-abandd-cart .rdt_TableBody .rdt_TableCell[data-column-id="3"] {
    min-width: 90px;
    max-width: 90px;
    justify-content: center;
    text-align: center;
}
.chart-abandd-cart .rdt_Table .rdt_TableBody a.button{
    background: transparent;
    color: #000 !important;
    min-width: 34px;
    border: 1px solid #e6e6e6;
    box-shadow: none;
}
.chart-abandd-cart .rdt_Table .rdt_TableBody a.button:hover{
    background: #000;
    color: #FFF !important;
    border: 1px solid #000;
}

.chart-top-selling .rdt_TableBody .rdt_TableRow a.title-tooltip {
    display: inline;
    bottom: -2px;
    left: 3px;
    position: relative;
}

.chart-high-customers {
    width: 100%;
    gap: 20px;
}

.statistics {
    width: calc(20% - 16px);
    padding: 20px;
    border: 1px solid #f6f6f6;
    box-shadow: 1px 0px 16px rgba(142, 134, 171, 0.08);
    place-items: start normal;
    border-radius: 10px;
}

.statistics:nth-child(1) {
    background-color: #def1dd;
}

.statistics:nth-child(2) {
    background-color: #f9e6c9;
}

.statistics:nth-child(3) {
    background-color: #f9dad9;
}

.statistics:nth-child(4) {
    background-color: #cff2f9;
}

.statistics:nth-child(5) {
    background-color: #f0daf7;
}

.statistics h2 {
    font-size: 22px;
}

.stat-hdr .stat-count {
    font-size: 18px;
    font-family: 'Proxima Nova Bl';
    padding: 14px 0 6px;
    color: #444846;
}

.stat-hdr .stat-icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    line-height: 34px;
    text-align: center;
    border-radius: 50%;
    color: #FFF;
    background: #000;
    font-size: 12px;
}

.stat-body p {
    color: #686464;
}

.statistics:nth-child(1) .stat-hdr .stat-icon {
    background: #5fa454;
}

.statistics:nth-child(2) .stat-hdr .stat-icon {
    background: #ef9538;
}

.statistics:nth-child(3) .stat-hdr .stat-icon {
    background: #d8453e;
}

.statistics:nth-child(4) .stat-hdr .stat-icon {
    background: #48b5c5;
}

.statistics:nth-child(5) .stat-hdr .stat-icon {
    background: #933faf;
}

/*** notification set ***/
.notification-field-set {
    padding: 20px 20px 15px;
    margin: 20px 0;
    background: #fbfbfb;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
}

.notification-field-set h3 {
    margin: 0 6px 20px;
}

.notification-field-set:last-of-type {
    margin-bottom: 0;
}

.notification-field-set .input-field label {
    width: 100%;
    margin: 0 0 6px;
    display: block;
}

.notification-field-set .check-box {
    width: max-content;
    min-width: 75px;
    display: inline-block;
    margin: 0 15px 0 0;
}

.notification-field-set .check-box input:checked+label:after {
    top: 2px;
}

.loader-btn {
    position: relative;
    background: #ec2f45 !important;
    color: #ec2f45 !important;
}

.loader-btn::after {
    position: absolute;
    content: '';
    left: calc(50% - 10px);
    top: calc(50% - 10px);
    border: 3px solid #FFF;
    border-radius: 50%;
    border-top: 3px solid #FFF;
    border-bottom: 3px solid #ec2f45;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

/* Added for vendors page style */
.dropdown-container {
    position: relative;
}

.date-picker-container {
    position: absolute;
    top: 100%;
    /* Places it directly below the dropdown */
    left: 0;
    background-color: white;
    padding: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    z-index: 10;
    width: 100%;
}

.date-picker-container label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

/* Added for vendors page style */

/**** ****/
.vendor-select-drop .dropdown-container .date-picker-container {
    position: absolute;
    width: 100%;
    min-width: 180px;
    border-radius: 5px;
    left: 4px;
    top: calc(100% + 6px);
    background-color: white;
    padding: 5px 15px 15px;
}

.vendor-select-drop .dropdown-container .date-picker-container label {
    margin: 10px 0 5px;
    font-size: 13px;
}

.vendor-select-drop .dropdown-container .date-picker-container::before {
    position: absolute;
    content: '';
    width: 12px;
    height: 12px;
    background: #FFF;
    left: 20px;
    top: -6px;
    transform: rotate(45deg);
}

.vendor-select-drop .dropdown-container .date-picker-container::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 15px;
    left: -6px;
    top: -10px;
}

.vendor-select-drop .dropdown-container .date-picker-container input[type="text"] {
    height: 40px;
    padding: 4px 10px;
}

.ord-data-loader {
    height: 300px;
}

.ord-data-loader .loading-buffer {
    height: 100%;
}

.ord-data-loader .loading-buffer::after {
    top: calc(35% - 10px);
}

.my-order-details-pop .checkout-table table thead tr td:first-child {
    min-width: 360px;
}
.abandoned-cart-details-pop .checkout-table table thead tr td:first-child {
    min-width: 240px;
}
.abandoned-cart-details-pop .checkout-table table thead tr td:nth-child(3) {
    min-width: 180px;
}

/**** skeletion loader ****/
.is-loading {
    width: 100%;
}

.is-loading,
.is-loading *:not(.image, h2, p) {
    background: #FFF !important;
}

.is-loading .image,
.is-loading h2,
.is-loading p {
    background: #eee;
    background: linear-gradient(110deg, #e6e6e6 8%, #f5f5f5 18%, #e6e6e6 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    -webkit-animation: 1.5s shine linear infinite;
    animation: 1.5s shine linear infinite;
    margin: 0 0 10px;
}

.is-loading .image {
    height: 200px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.is-loading p {
    height: 20px;
}

.is-loading p:nth-of-type(5n + 1) {
    height: 40px;
    margin-bottom: 20px;
    width: 65%;
}

.is-loading p:nth-of-type(5n + 2) {
    width: 80%;
}

.is-loading p:nth-of-type(5n + 3) {
    width: 90%;
}

.is-loading p:nth-of-type(5n + 4) {
    width: 70%;
}

.is-loading p:nth-of-type(5n + 5) {
    width: 60%;
    margin-bottom: 50px;
}

@-webkit-keyframes shine {
    to {
        background-position-x: -200%;
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.my-order-details-pop .checkout-table table thead.ord-grand-total tr td .tot-ofr-applied {
    font-size: 12px;
    display: block;
    padding-right: 5px;
    font-style: italic;
}

.tot-ofr-applied {
    display: block;
    font-size: 12px;
    font-family: 'Proxima-Nova-Bold';
    font-style: italic;
    text-align: right;
}

/*** filter dropdown datepicker ***/
.chart-fltr .date-picker-container input[type="text"] {
    padding: 4px 6px;
    font-size: 13px;
    height: 34px;
    font-family: 'Proxima-Nova-Bold';
    color: #222;
}

.chart-fltr .date-picker-container input[type="text"]::placeholder {
    color: #222;
}

.chart-fltr .date-picker-container label {
    font-size: 12px;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
    left: calc(75% - 25px) !important;
}

.chart-high-customers .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    left: calc(15% - 25px) !important;
}

.chart-order-count .chart-Hdr-txt {
    width: auto;
}

.fltr-sub-val {
    width: 100%;
    margin: 2px 0 0;
}

.chart-top-selling .chart-Hdr .fltr-sub-val {
    margin: -3px 0 0;
}

.chart-Hdr .fltr-val {
    font-size: 12px;
    line-height: 1.125;
    display: block;
    margin: 3px 0 0;
    font-family: 'Proxima-Nova-Bold';
}

.chart-Hdr .fltr-val strong {
    font-family: 'Proxima-Nova-Bold';
}

.chart-fltr .date-picker-container::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 80px;
    left: 0;
    top: 100%;
}
.sectors-detail-form {
    width: 100%;
    padding: 0 6px;
}
.sectors-detail-form .btnfield {
    width: 100%;
    padding: 0 2px;
}

.chart-container .no-records-found {
    display: flex;
    width: 100%;
    height: 400px;
    background: transparent;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ed5264;
}
.chart-container .no-records-found p {
    color: #ed5264;
}
.chart-top-selling .rdt_TableBody .rdt_TableRow:has(.free) {
    background: #fff5d1;
}
.chart-week-revenue .chart-Hdr .chart-fltr select{
    min-width: initial;
}
.chart-qtly-revenue .chart-Hdr .chart-fltr select{
    min-width: initial;
}
.vendor-table-view table.vendor-table.dtp-price-table tr th {
    padding: 10px 14px;
    font-family: 'Proxima-Nova-Bold';
}
.vendor-table-view table.vendor-table.dtp-price-table td:first-of-type{
    font-weight: normal;
}

.ctgry-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 4px 0 -4px;
    /* filter: invert(29%) sepia(0%) saturate(585%) hue-rotate(138deg) brightness(102%) contrast(82%); */
}
a.title-tooltip:hover .ctgry-icon{
    /* filter: invert(36%) sepia(68%) saturate(4022%) hue-rotate(333deg) brightness(91%) contrast(105%); */
}
.ctgry-icon.pizza{
    background: url(../images/pizza.png) scroll center no-repeat;
    background-size: 22px;
}
.ctgry-icon.pasta{
    background: url(../images/pasta.png) scroll center no-repeat;
    background-size: 29px;
    width: 28px;
    height: 28px;
}
.ctgry-icon.burrito{
    background: url(../images/burrito.png) scroll center no-repeat;
    background-size: 24px;
}
.check-box label .ctgry-icon {
    width: 20px;
    height: 20px;
    background-size: 20px;
}

.addroles-fieldset .client-detail-form .check-box input:checked+label:after {
    top: 2px;
}

.free-sample-details-pop .order-top .bottom .order_account_details p{
    /* display: flex; */
    flex-wrap: wrap;
}
.free-sample-details-pop .bottom.order-flex .order_account_details {
    width: 50%;
    max-width: 420px;
    padding: 0;
}
.order-top .bottom .order_account_details p span.ord-act-data {
    width: calc(100% - 125px);
    word-break: break-word;
    padding-left: 2px;
}
.order-top .bottom .order_account_details p span.highlight{
    font-family: 'Proxima-Nova-Bold';
    width: 125px;
}
.free-sample-details-pop .checkout-table table tbody tr td:first-child {
    min-width: 600px;
}
.ord-free-disclaimer {
    margin: -15px 0 0;
    font-size: 13px;
    font-family: 'Proxima-Nova-Bold';
    font-style: italic;
}

.table_overflow.free-sample-orders .rdt_TableHeadRow .rdt_TableCol[data-column-id="1"],
.table_overflow.free-sample-orders .rdt_TableBody .rdt_TableCell[data-column-id="1"]{
    min-width: 100px;
    max-width: 120px;
}
.table_overflow.free-sample-orders .rdt_TableHeadRow .rdt_TableCol[data-column-id="2"],
.table_overflow.free-sample-orders .rdt_TableBody .rdt_TableCell[data-column-id="2"]{
    min-width: 160px;
    max-width: 180px;
}
.table_overflow.free-sample-orders .rdt_TableHeadRow .rdt_TableCol[data-column-id="3"],
.table_overflow.free-sample-orders .rdt_TableBody .rdt_TableCell[data-column-id="3"]{
    min-width: 120px;
    max-width: 300px;
}
.table_overflow.free-sample-orders .rdt_TableHeadRow .rdt_TableCol[data-column-id="4"],
.table_overflow.free-sample-orders .rdt_TableBody .rdt_TableCell[data-column-id="4"]{
    min-width: 120px;
    max-width: 130px;
}
.table_overflow.free-sample-orders .rdt_TableHeadRow .rdt_TableCol[data-column-id="5"],
.table_overflow.free-sample-orders .rdt_TableBody .rdt_TableCell[data-column-id="5"]{
    min-width: 120px;
    max-width: 150px;
}
.table_overflow.free-sample-orders .rdt_TableHeadRow .rdt_TableCol[data-column-id="6"],
.table_overflow.free-sample-orders .rdt_TableBody .rdt_TableCell[data-column-id="6"]{
    min-width: 170px;
    max-width: 170px;
}

.search-input-wrapper {
    position: relative;
}
.customers-search .orders-dropdown select.search-field {
    width: 160px;
}
  
.suggestions-list {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    list-style: none;
    padding: 0px;
    margin: 0;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 99;
}

.suggestions-list li {
    padding: 6px 10px;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.125;
}

.suggestions-list li:hover {
    background: #f0f0f0;
}

/**** Add free sample Product ****/
.add-free-samp-disp {
    margin: 10px 5px 20px;
    padding: 15px 15px;
    border: 1px solid #d6d6d6d6;
    border-radius: 4px;
    width: 100%;
    position: relative;
}
.add-free-samp-chks {
    padding: 5px 0;
    margin: 0 0 0px;
}
.add-free-samp-chks .add-check-box-wrap {
    padding: 20px 20px 20px;
    background: #f1f1f1;
    border-radius: 4px;
    margin: 0 0 20px;
}
.custom_check-box .edit-free-cat-chks .check-box {
    width: fit-content !important;
    margin-right: 20px;
}
.add-free-samp-chks .check-box {
    margin: 5px 0 -5px;
}
.add-check-box-wrap .switch-button-holder {
    padding: 5px 0px 0px;
    border: none;
}
.add-check-box-wrap .switch-button-holder label {
    font-size: 15px;
}
.add-free-samp-chks .check-box input:checked+label:after{
    top: 3px;
}
.add-check-box-wrap .switch-button-holder .switch-button {
    transform: scale(0.8);
    margin: 0px 0px -15px 10px;
}
.cartVal{
    max-width: 140px;
    clear: left;
    display: block;
}