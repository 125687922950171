.sideNav {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 250px;
    width: 100%;
    height: 100%;
    background: rgba(14,12,6,0.88);
    box-shadow: 3px 0px 23px 0px rgba(21, 20, 16, 0.25);
    background: #fff2f3;
    box-shadow: 3px 0px 23px 0px rgba(21, 20, 16, 0.12);
    transition: all 0.4s ease;
}
.innside {
    display: flex;
    flex-direction: column;
    padding: 0px 0 15px;
    height: 100%;
    /*overflow: hidden;
    overflow-y: auto;*/
}
.menuOpen {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 10px;
    top: 15px;
    text-align: center;
    line-height: 38px;
    background: #47453e;
    border-radius: 4px;
    color: #FFF;
    transition: all 0.5s ease;
    cursor: pointer;
}
.menuOpen span i {
    font-size: 26px;
}
.menuOpen:hover {
    background: #ec2f45;
    color: #FFF;
}
.dashRight {
    flex: 1 1 auto;
    max-width: 250px;
    height: 100%;
    z-index: 3;
    transition: all 0.4s ease;
}
.innside:hover .menuOpen{
    opacity: 1;
    visibility: visible;
}
.innside::-webkit-scrollbar {
    width: 6px;
}
.innside::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.innside::-webkit-scrollbar-thumb {
    background-color: #868686;
}
.userIco {
    margin: 0px 0 30px;
    padding: 25px 15px 23px;
    min-height: 100px;
    display: flex;
    flex-flow: column;
    white-space: nowrap;
    align-items: center;
    /* border-bottom: 1px solid #464646; */
    background: #353226E3;
    box-shadow: 0px 2px 8px rgba(40, 40, 40, 0.22);
}
.userIco span {
    display: flex;
    justify-content: center;
    align-items: center;
}
.userIco span img {
    width: 28px;
    height: 28px;
    object-fit: cover;
    object-position: top center;
    background-color: #fff;
    padding: 4px;
    border: 1px solid #fcc254;
    border-radius: 50%;
}
.userIco .title {
    font-size: 16px;
    font-family: 'Proxima Nova Rg';
    letter-spacing: -0.4px;
    padding: 8px 6px 0;
    margin: 0px;
    color: #FFF;
    /*width: calc(100% - 65px);*/
    white-space: normal;
    text-align: center;
}
.innside nav {
    position: relative;
    overflow-y: auto;
}
.innside nav::-webkit-scrollbar {
    width: 3px;
}
.innside nav::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.innside nav::-webkit-scrollbar-thumb {
    background-color: #868686;
}
.innside nav ul {
    
}
.innside nav ul li {
    margin: 8px 0;
    padding: 0 15px;
    z-index: 11;
}
.innside nav ul li a {
    position: relative;
    display: flex;
    white-space: nowrap;
    align-items: center;
    color: #fff;
    color: #333;
    user-select: none;
    padding: 10px 11px;
    /*background: rgba(252,192,84,0.9);*/
    border-radius: 8px;
    transition: background-color .3s ease-in-out;
}
.innside nav ul li a:hover {
    background-color: rgba(236,47,69,0.9);
    color: #FFF;
}
.innside nav ul li a i{
    margin-right: 10px;
    font-size: 16px;
    transition: all 0.2s ease-in-out;
    width: 14px;
    font-size: 14px;
    text-align: center;
}
.innside nav ul li a .menuTxt{
    display: inline-block;
    transition: all 0.4s ease;
    opacity: 1;
}
/* .innside nav > ul > li:global(.active) {
    padding: 0 0 0 15px;
} */
.innside nav > ul > li:global(.active) > a {
    /*font-weight: 600;
    color: #121117; */
    background: rgba(236,47,69,0.9);
    /* border-right: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; */
    color: #FFF;
    box-shadow: 0px 5px 13px rgba(40, 40, 40, 0.12);
}
.innside nav > ul > li:global(.active,.show) > a {
    /*background: #393934;*/
}
.innside nav > ul > li:global(.active) > a::after {
    content: "";
}
.innside nav ul .dropMenu .submenu {
    display: none;
    overflow: hidden;
    transition: all .5s linear;
}
.innside nav ul :global(.show).dropMenu .submenu {
    display: block;
    animation: heightAnim .5s forwards;
}
@keyframes heightAnim {
    0% {
        height: 0%;
    }
    50% {
        height: 50%;
    }
    100% {
        height: 100%;
    }
}
.innside nav ul .dropMenu .submenu ul {
    padding: 8px 4px 10px 1px;
    background: #393934;
    background-color: #f1dee0;
}
.innside nav ul .dropMenu .submenu ul li {
    padding: 0 0 0;
    margin: 0 0;
}
.innside nav ul .dropMenu .submenu ul li a {
    /* background: #393934; */
    border-radius: 0px;
    padding: 10px 10px;
}
.innside nav ul .dropMenu .submenu ul li:global(.active) a {
    /*background:#6b8f00;*/
    color:#ec2f45;
}
.innside nav ul .dropMenu .submenu ul li a:hover {
    background: transparent;
    color: #ec2f45;
}
.innside nav ul .dropMenu > a {
    position: relative;
}
.innside nav ul .dropMenu > a :global(.fa-angle-down) {
    position: absolute;
    font-size: 20px;
    font-weight: bold;
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
    right: 8px;
    transition: all .5s linear;
}
.innside nav ul :global(.show).dropMenu > a :global(.fa-angle-down) {
    transform: translateY(-50%) rotate(180deg);
    transition: all .5s linear;
}
.navBot {
    margin: 0 15px 0;
    display: none; /** hide logout in sidebar **/
}
.navBot a {
    display: flex;
    white-space: nowrap;
    align-items: center;
    padding: 10px 10px;
    color: #fff;
    background: #121117;
    border-radius: 4px;
}
.navBot a .menuTxt{
    opacity: 1;
    transition: all 0.4s ease;
    margin-left: 9px;
}
.navBot a:hover {
    background: rgba(236,47,69,0.9);
}
.navBot a .fa {
    font-weight: 400;
}

@-webkit-keyframes menu_up{
	0%{-webkit-transform:translateX(-150px);opacity:0;}
	100%{-webkit-transform:translateX(0px);opacity:1;}
}
@keyframes menu_up{
	0%{-webkit-transform:translateX(-100px);transform:translateX(-150px);opacity:0;}
	100%{-webkit-transform:translateX(0px);transform:translateX(0px);opacity:1;}
}

@media (max-width: 1280px) {
    .sideNav {
        max-width: 220px;
    }
    .dashRight {
        max-width: 220px;
    }
    .innside nav ul li a {
        font-size: 15px;
    }
}

/**** side menu colapse ****/
.dashRight:global(.open) .sideNav .innside .menuOpen{
    transform: rotate(180deg);
    right: 10px;
}
.dashRight:global(.open) {
    max-width: 70px;
}
.dash-left{
    max-width: calc(100% - 70px);
}
.dashRight:global(.open) .sideNav {
    max-width: 70px;
}
.dashRight:global(.open) .innside nav ul li a,
.dashRight:global(.open) .navBot a{
    max-width: 39px;
}
.dashRight:global(.open) .innside nav ul li a .menuTxt,
.dashRight:global(.open) .navBot a .menuTxt{
    font-size: 0;
    display: none;
}
.dashRight:global(.open) .innside nav ul li a:hover .menuTxt{
    position: fixed;
    display: inline-block;
    font-size: 14px;
    left: 75px;
    padding: 4px 10px;
    background: #181814cf;
}
.dashRight:global(.open) .innside nav ul li a:hover .menuTxt::before{
    position: absolute;
    content: "";
    border-style: solid;
    border-width: 5px;
    border-color: transparent #181814cf transparent transparent;
    left: -9px;
    top: 6px;
}
.dashRight:global(.open) .innside nav ul :global(.show).dropMenu .submenu {
    display: block;
    animation: sidebar_heightAnim__UbVH5 .5s forwards;
    position: fixed;
    transform: translate(70px, -38px);
    left: 0;
    max-height: 200px;
    overflow-y: auto;
}
.dashRight:global(.open) .innside nav ul :global(.show).dropMenu .submenu::-webkit-scrollbar {
    width: 5px;
}
.dashRight:global(.open) .innside nav ul :global(.show).dropMenu .submenu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.dashRight:global(.open) .innside nav ul :global(.show).dropMenu .submenu::-webkit-scrollbar-thumb {
    background-color: #868686;
}

.dashRight:global(.open) .innside nav ul :global(.show).dropMenu .submenu ul li a{
    max-width: initial;
    min-width: 200px;
}
.dashRight:global(.open) .innside nav ul :global(.show).dropMenu .submenu ul li a .menuTxt{
    font-size: 14px;
    display: inline-block;
    margin-left: 8px;
    opacity: 1;
}
.dashRight:global(.open) .innside nav ul :global(.show).dropMenu .submenu ul li a:hover .menuTxt{
    position: relative;
    font-size: 14px;
    left: 0px;
    padding: 0px;
    background: transparent;
}
.dashRight:global(.open) .innside nav ul :global(.show).dropMenu .submenu ul li a:hover .menuTxt::before{
    display: none;
}
.dashRight:global(.open) .innside nav ul li a i{
    margin-right: 0px;
}
.dashRight:global(.open) .userIco span{
    display: none;
}
.dashRight:global(.open) .userIco .title{
    font-size: 0px;
}
.dashRight:global(.open) .innside nav ul .dropMenu > a :global(.fa-angle-down){
    display: none;
}
/**** side menu hover ****/
.dashRight:hover {
    /*max-width: 250px;*/
}
/*
.dashRight:hover .sideNav {
    max-width: 250px;
}
.dashRight:hover .innside nav ul li a .menuTxt,
.dashRight:hover .navBot a .menuTxt{
    font-size: 16px;
    opacity: 1;
    display: inline-block;
}
.dashRight:hover .innside nav ul li a i{
    margin-right: 10px;
}
.dashRight:hover .userIco span {
    display: flex;
}
.dashRight:hover .userIco .title {
    font-size: 16px;
}
.dashRight:hover .innside nav ul .dropMenu > a :global(.fa-angle-down){
    display: inline-block;
}
*/
