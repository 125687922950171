/* Font Size START */

.font22 {
    font-size: var(--fontSize22);
}
.font24 {
    font-size: var(--fontSize24);
}
.font28 {
    font-size: var(--fontSize28);
}
.font32 {
    font-size: var(--fontSize32);
}

/* Font Size End */
@media (max-width: 1300px) {
    .chart-category-sales .chart-Hdr .chart-fltr select{
        min-width: 120px;
        max-width: 125px;
    }
    .customers-search .orders-dropdown select.search-field {
        width: 122px;
        font-size: 13px;
    }
    .orders-dropdown select{ font-size: 13px; }
    .orders-dropdown select option {
        padding: 5px 8px;
        font-size: 13px;
    }
    .vendors-table-wrap .customers-search .table-search {
        width: 260px;
    }
    .orders-dropdown.vendor-select-drop {
        max-width: calc(50% - 50px);
    }

}
@media (max-width: 1280px) {
    .dash-left .cart-block .container{ max-width: 980px;}
    .checkout-table .products-table .rdt_TableHeadRow .rdt_TableCol[data-column-id="1"],
    .checkout-table .products-table .rdt_TableBody .rdt_TableCell[data-column-id="1"]{
        max-width: 115px;
    }
    .checkout-table .products-table .rdt_TableHeadRow .rdt_TableCol[data-column-id="5"], .checkout-table .products-table .rdt_TableBody .rdt_TableCell[data-column-id="5"] {
        max-width: 160px;
    }
    .checkout-table .products-table .rdt_TableHeadRow .rdt_TableCol[data-column-id="2"], .checkout-table .products-table .rdt_TableBody .rdt_TableCell[data-column-id="2"] {
        min-width: 190px;
    }
    .vendor-list-table .rdt_TableHeadRow .rdt_TableCol[data-column-id="3"],
    .vendor-list-table .rdt_TableBody .rdt_TableCell[data-column-id="3"]{
        min-width: 150px;
    }
    .vendor-list-table .rdt_TableHeadRow .rdt_TableCol[data-column-id="5"],
    .vendor-list-table .rdt_TableBody .rdt_TableCell[data-column-id="5"]{
        min-width: 90px;
        max-width: 90px;
        padding: 0 0px;
    }
    .admin-account-table .rdt_Table .rdt_TableBody .rdt_TableCell[data-column-id="5"], .admin-account-table .rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="5"], .admin-account-table .rdt_Table .rdt_TableBody .rdt_TableCell[data-column-id="6"], .admin-account-table .rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="6"] {
        min-width: 120px;
    }
    .products-search-left .table-search {
        width: 380px;
    }
    .chart-Hdr .chart-fltr select {
        min-width: 140px;
    }

}

@media (max-width: 1024px) {

    :root {
        --fontSize22: calc(18px + (22 - 18) * ((100vw - 380px) / (1024 - 380)));
        --fontSize24: calc(20px + (24 - 20) * ((100vw - 380px) / (1024 - 380)));
        --fontSize28: calc(22px + (28 - 22) * ((100vw - 380px) / (1024 - 380)));
        --fontSize32: calc(24px + (32 - 24) * ((100vw - 380px) / (1024 - 380)));
    }
    .sm-container {
        padding: 0 15px;
    }
    .cater-sec h1 {
        margin: 0 0 25px;
    }
    .cart-item {
        max-width: 50%;
        width: 100%;
        margin: 0 0 15px;
    }
    .cart-innr {
        margin: 0 7px;
    }
    
}

@media (max-width: 980px) {

    .checkout-block {
        margin: 35px 0 15px;
    }
    .checkout-title {
        margin: 0 0 15px;
    }
    .prot-itme {
        max-width: 33.333%;
    }
    .dt-flx {
        margin: 25px 0 15px;
    }

}

@media (max-width: 860px) {

    /* Checkout Table -- START */

    .checkout-table table thead {display: none;}
    .checkout-table table tbody tr {
        display: flex;
        flex-flow: column wrap;
        padding: 10px 0;
        border-top: 1px solid #959699;
        border-bottom: 1px solid #959699;
    }
    .checkout-table table tbody tr td::before {
        content: "" attr(data-title) ":";
        font-size: 20px;
        font-weight: 700;
        color: #2B2E34;
        margin: 0 10px 0 0;
        text-align: left;
    }
    .checkout-table table tbody td.table-qty {
        width: auto;
    }
    .checkout-table table tbody tr td.table-tol {
        width: auto;
    }
    .checkout-table table tbody tr td {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 5px 1px;
        text-align: right;
    }
    .checkout-table table tbody tr td[data-title="Ingredient"] {
        text-align: left;
    }
    .checkout-table table tbody tr.no-line {
        flex-flow: row wrap;
        justify-content: flex-end;
    }
    .checkout-table table tbody tr.no-line td::before {display: none;}
    .checkout-table table tbody tr td span.strong {
        margin: 0 7px 0 0;
    }
    .checkout-table table tbody tr.total td {
        display: block;
        width: 50%;
        text-align: right;
        padding: 10px 0;
    }
    .checkout-table table tbody tr.no-line td:empty {
        display: none;
    }
    .checkout-table table tbody tr td.table-del {
        justify-content: flex-end;
        width: auto;
        padding: 10px 0 0;
        margin: 10px 0 0;
        border-top: 1px solid #dedede;
        margin-left: auto;
    }
    .checkout-table table tbody tr td.table-del .ico-del:hover::before {
        color: #d30000;
    }
    .checkout-table table tbody tr td.table-del::before {
        display: none;
    }
    .checkout-table table tbody tr td.table-del .ico-del::before {
        content: "Delete";
        font-weight: 600;
        color: #000;
        margin: 0 6px 0 0;
    }
    .checkout-table table tbody tr.no-line.lin-btn td {
        flex: 1 1 auto;
        width: 50%;
    }
    .checkout-table table tbody tr td .button {
        font-size: 17px;
        font-weight: 600;
    }
    .checkout-table table tbody tr td .button.back-btn {
        display: flex;
        align-items: center;
    }
    .checkout-table table tbody tr td .form-control {
        padding: 5px 6px;
        border: 1px solid #2B2E34;
        border-radius: 4px;
    }

    /* Checkout Table -- END */

    /* My Account Table -- START */

    .acc-table thead {
        display: none;
    }
    .acc-table tbody tr {
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
    }
    .acc-table tbody tr td {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 5px 1px;
        text-align: left;
    }
    .acc-table tbody tr td[data-title]::before {
        content: "" attr(data-title) ":";
        font-size: 20px;
        font-weight: 700;
        color: #2B2E34;
        margin: 0 10px 0 0;
        text-align: left;
    }
    .acc-table tbody tr td:not([data-title]) {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
    }
    .acc-table tbody tr td:empty {
        display: none;
    }
    .acc-table tbody tr.total {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 10px 0;
    }
    .acc-table tbody tr.total td {
        text-align: right;
    }
    .acc-table tbody tr.total td.left {
        display: block;
        text-align: left;
    }
    /* My Account Table -- END */
    
    .custom-file {
        width: 100%;
        margin: 0 0 10px;
    }

}

@media (max-width: 780px) {
    .input-two-col {
        width: 100%;
    }
    .input-three-col {
        width: 50%;
    }
    .input-four-col {
        width: 50%;
    }
}

@media (max-width: 680px) {

    .prot-itme {
        max-width: 50%;
    }
    .cater-item {
        max-width: 100%;
    }
    .cater-innr {
        padding: 10px;
    }
    .order-innr {
        padding: 5px;
    }

}

@media (max-width: 600px) {
    .input-two-col {
        width: 100%;
    }
    .input-three-col {
        width: 100%;
    }
    .input-four-col {
        width: 100%;
    }
}

@media (max-width: 460px) {

    .cart-item {
        max-width: 100%;
    }
    .cart-innr {
        max-width: 300px;
        margin: 0 auto;
    }
    .pay-popup {
        max-width: 90vw;
    }
    .dt-flx > div {
        max-width: 100%;
    }

}

@media (max-width: 370px) {

    .prot-itme {
        max-width: 100%;
    }

}