.popupsec {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}
.popupBlock {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 980px;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
    width: 100%;
    margin: 0 auto;
    z-index: 10;
    animation: topanim .5s forwards;
    scrollbar-color: #d6d6d6 #f6f6f6;
    scrollbar-width: thin;
}
:global(.info-poup) .popupBlock {
    max-width: 600px;
}
:global(.changepassword-pop) .popupBlock {
    max-width: 600px;
}
:global(.prt-poup) .popupBlock {
    max-width: 400px;
}
:global(.prt-poup) .popupBody {
    padding: 10px;
}
:global(.pop-container-md) .popupBlock {
    max-width: 800px;
}
:global(.pop-container-sm) .popupBlock {
    max-width: 600px;
}
:global(.pop-container-xs) .popupBlock {
    max-width: 400px;
}

.popupBlock::-webkit-scrollbar {
    width: 6px;
    background-color: #f2f4df;
}
.popupBlock::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(240, 250, 255, 0.9);
}
   
.popupBlock::-webkit-scrollbar-thumb {
    background-color: #d6d6d6;
}
.popupInnr {
    position: relative;
    background: #fff;
}
@keyframes topanim {
   0% {
       opacity: 0;
       top: 65%;
   }
   100% {
        opacity: 1;
        top: 50%;
   }
}

.close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 25px;
    height: 25px;
    opacity: .5;
    cursor: pointer;
}
.close:hover {
    opacity: 1;
}
.close::before,
.close::after {
    content: "";
    position: absolute;
    left: 15px;
    content: ' ';
    height: 20px;
    width: 2.2px;
    background-color: #000;
}
.close::before {
    transform: rotate(45deg);
}
.close::after {
    transform: rotate(-45deg);
}
:global(.info-poup) .popupHead {
    padding: 15px 10px 15px;
}
:global(.prt-poup) .popupHead {
    display: block;
}

.popupHead {
    padding: 15px 10px 15px;
    background: #f2f4df;
    border-bottom: 1px solid #c6c6c6;
}
.popupTitle {
    composes: font32 from '../assets/css/responsive.css';
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    text-align: center;
    margin: 0 0 0;
}
.popupBody {
    padding: 15px 30px;
}
.popupFoot {

}

:global(.changepassword-pop) .popupHead {
    padding: 0;
}

.popupFoot:empty {display: none;}
.popupOvly {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 80%);
    z-index: -1;
}

/* OrderDetail Popup -- START */

:global(.order-pop) {
    background: rgba(0,0,0,0.8);
}
:global(.order-pop) .popupHead {
    padding: 0 0 0;
}
.popupFoot :global(.popup-btn) {
    display: block;
    color: #fff;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.3px;
    padding: 10px 15px;
    background: #9c0;
    text-align: center;
    transition: all .5 ease-in-out;
}
.popupFoot :global(.popup-btn:hover) {
    background: #000;
    transition: all .5 ease-in-out;
}
:global(.order-pop) .popupFoot {
    position: sticky;
    bottom: 0;
    width: 100%;
}

/* OrderDetail Popup -- END */

@media (max-width: 980px) {

    .popupBlock {
        max-width: calc(100vw - 40px);
    }
    .popupBody {
        padding: 15px 30px;
    }

}