/* Header -- START */

:root {
	--e-color-squid-black: #332e20; 
    --e-color-mustard-yellow: #fcc254; 
    --e-color-cream-white: #eeeeda; 
    --e-color-paprika-red: #ec2f45;
    --e-color-himalayan-salt: #ffe1e3; 
    --e-color-dark: #353226; 
    --e-color-white: #FFFFFF; 
}

header {
    position: relative;
    padding: 18px 0;
    background: var(--e-color-squid-black);
    background-color: #353226E3;
    box-shadow: 0px 2px 6px rgba(40, 40, 40, 0.22);
    margin: 0 0 14px;
    z-index: 4;
}
header::after {
    content: "";
    position: absolute;
    bottom: -14px;
    width: 100%;
    height: 14px;
    background: rgb(53 50 38 / 89%);
}
.headFlx{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;
}
.logo {
    height: 80px;
}
.logo img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.headTxt {
    composes: font24 from "../assets/css/responsive.css";
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    margin: 0 0 25px;
}
.headTxt span{
    text-transform: none;
    display: block;
}
.headRight {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0 0 25px;
}
.rgtFlx {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}
.cartIco {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin: 0 0 0 10px;
}
.cartIco .faIco {
    font-size: 25px;
    line-height: 0;
    color: #fff;
}
.cartIco .count {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #fff8f8;
    background: #000;
    border-radius: 50%;
    padding: 1px;
    top: -17px;
    right: -13px;
}
.acc {
    position: relative;
}
.logout {
    display: none;
    position: absolute;
    top: 100%;
    right: -10px;
    min-width: 140px;
    background: #fff;
    padding: 8px 10px;
    border: 1px solid #ed7c88;
    border-radius: 5px;
    box-shadow: -4px 4px 7px 0px rgb(0 0 0 / 15%);
    z-index: 1;
}

.logouts {
    position: relative;
    background: #fff;
    padding: 6px 8px;
    border: 1px solid #ed7c88;
    border-radius: 5px;
    box-shadow: -4px 4px 7px 0px rgb(0 0 0 / 15%);
    z-index: 1;
    cursor: pointer;
    display: none;
}

.acc:hover .logout {
    display: block;
    animation: scrollTop .5s forwards;
}
@keyframes scrollTop {
    0% {
        opacity: 0.5;
        transform: translateY(15px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.logout::before {
    content: "";
    position: absolute;
    top: -7px;
    right: 10px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #fff;
    z-index: -1;
}
.logout span {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.25px;
    padding: 6px 0;
    border-bottom: 1px solid #ed7c88;
}
.logout span a, .logouts span {
    font-size: 15px;
    color: #000;
    font-size: 15px;
    font-family: 'Proxima-Nova-Bold';
}
.logout span:first-child {
    padding-top: 0;
}
.logout span:last-child {
    padding-bottom: 0px;
    border-bottom: 0px;
}
.logout span:hover,
.logout span:hover a,
.logouts span:hover{
    color: #dd3144;
}
.logout span .ico,
.logouts span .ico {
    display: inline-block;
    margin-right: 5px;
}
.headLog {
    display: flex;
    align-items: flex-end;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    margin: 0 0 10px;
}
.headLog img {
    margin-left: 10px;
}
.headLog img.nrm {
    display: block;
}
.headLog img.hov {
    display: none;
}
.headLog:hover {
    color: #dd3144;
}
.headLog:hover img.nrm {
    display: none;
}
.headLog:hover img.hov {
    display: block;
}
.headLink {
    composes: font24 from "../assets/css/responsive.css";
    text-transform: uppercase;
    color: #fff;
}
.headLink:hover {
    color: #000;
}

/* Header -- END */

/* Footer -- START */
footer {
    position: absolute;
    width: 100%;
    background: var(--e-color-cream-white);
    padding: 40px 0 0;
    bottom: 0;
    left: 0;
}
.top {
    text-align: center;
}
.top span {
    position: relative;
    font-size: 16px;
    color: #332e20;
    padding: 0 10px 0 0;
    margin: 0 10px 0 0;
}
.top span:last-child::after {
    display: none;
}
.top span::after {
    content: "";
    display: inline-block;
    position: relative;
    right: -10px;
    width: 1px;
    height: 13px;
    background: #9c0;
}
.top span a {
    color: #332e20;
}
.top span a:hover {
    color: #ec2f45;
}
.addr {

}
.link {
    
}
.bottom {
    text-align: center;
}
.footnav {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 15px 0 10px;
}
.footnav li {
    position: relative;
    padding: 0 10px 0 0;
    margin: 0 10px 0 0;
}
.footnav li::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 1px;
    height: 13px;
    background: #332e20;
}
.footnav li:last-child::after {
    display: none;
}
.footnav li a {
    font-size: 16px;
    color: #332e20;
}
.footnav li a:hover {
    color: #ec2f45;
}
.footerCopy{
    font-size: 14px;
    color: #332e20;
    text-align: center;
    padding: 15px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #cccdb2;
}

/** Footer New **/
.footerWrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 15px;
    padding: 0 15px;
}
.footerLeft{
    display: flex;
    flex-wrap: wrap;
}
.footerRight{

}
.footerRight .link {
    display: block;
    line-height: 1.4;
}
.footerRight .link a {
    color: #332e20;
}
.footerRight .link a:hover {
    color: #dd3144;
}
.footerRight .link a i {
    margin-right: 5px;
}
.footAdr{
    padding-right: 50px;
}
.footLogo{
    padding-right: 30px;
    padding-left: 20px;
}
.footLogo a{
    display: inline-block;
    width: 100px;
}
.footLogo img {
    filter: brightness(0.3);
}
.footAdr p{
    line-height: 1.3;
    color: #332e20;
}
.footLinks{
    display: flex;
    flex-wrap: wrap;
}
.footLinks .footnav {
    display: block;
    margin: 0px;
    max-width: 200px;
}
.footLinks .footnav li {
    position: relative;
    padding: 0 10px 0 0;
    margin: 0px 10px 8px 0;
}
.footLinks .footnav li::after {
    content: "";
    display: none;
}
.CopyTxt{

}
.footSocial{

}
.footSocial ul{
    display: flex;
    flex-wrap: wrap;
}
.footSocial ul li{
    padding: 0 3px;
}
.footSocial ul li img{
    width: 12px;
}
.footSocial ul li a{
    width: 32px;
    height: 32px;
    display: block;
    line-height: 34px;
    text-align: center;
    background: #332e20;
    border-radius: 50%;
    color: #FFF;
    transition: all 0.4s ease;
}
.footSocial ul li a:hover{
    background: #ec2f45;
}

/* Footer -- End */

@media (max-width: 990px) {

    .logo {
        order: 1;
    }
    .logo img {
        max-width: 170px;
    }
    .headTxt {
        order: 3;
        flex: 1 1 auto;
        width: 100%;
        text-align: center;
        margin: 10px 0 0;
    }
    .headRight {
        order: 2;
    }
    .top span {
        line-height: 1.5;
    }

    /** Footer **/
    footer{
        padding: 24px 0 0;
    }
    .footerWrap{
        justify-content: center;
        margin: 0 0 20px;
    }
    .footerLeft{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        width: 100%;
    }
    .footerCopy{
        padding: 18px 0;
        flex-flow: wrap-reverse;
    }
    .footAdr{
        width: 100%;
        padding: 0;
    }
    .CopyTxt{
        width: 100%;
    }
    .footLinks{
        width: 100%;
        flex-flow: column;
        padding: 10px 0;
        margin: 0;
    }
    .footLogo{
        padding: 10px 0 18px;
    }
    .footLinks .footnav {
        max-width: initial;
        padding: 8px 0;
    }
    .footLinks .footnav li {
        padding: 0;
    }
    .footSocial{
        width: 100%;
        padding: 5px 0 20px;
    }
    .footSocial ul{
        justify-content: center;
    }

}

@media (max-width: 768px) {

    .headRight {
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
    }
    .headLog {
        cursor: pointer;
        position: relative;
        font-size: 0px;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        line-height: 0;
        background: #9c0;
        border: 2px solid #fff;
        color: #fff;
        border-radius: 50%;
        overflow: hidden;
        margin: 0;
        transition: all .5s ease-in-out;
    }
    .headLog img.nrm,
    .headLog img.hov,
    .acc:hover .headLog img.hov {display: none;}
    .headLog::after {
        content: "\f2c0";
        font-family: FontAwesome;
        font-size: 20px;
    }
    .acc:hover .headLog {
        background: #000;
        transition: all .5s ease-in-out;
    }
    .acc:hover .headLog::after {
        color: #fff;
    }
    .headLink {
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0px;
        width: 40px;
        height: 40px;
        background: #000;
        border: 2px solid #fff;
        color: #fff;
        border-radius: 50%;
        transition: all .5s ease-in-out;
        margin: 0 0 0 10px;
    }
    .headLink::after {
        content: "\f095";
        font-family: FontAwesome;
        font-size: 20px;
    }
    .headLink:hover {
        background: #9c0;
        transition: all .5s ease-in-out;
    }
    .rgtFlx {
        flex-flow: row-reverse wrap;
    }
    .cartIco {
        margin: 10px 25px 0 0;
    }

}

/* 01/04/2022 -- START */

:global(.dash-left) header {
    padding: 10px 0;
    margin: 0 -15px 15px;
}
:global(.dash-left) header::after {
    display: none;
}
:global(.dash-left) .logo img {
    max-width: 70px;
}
:global(.dash-left) .headTxt {
    margin: 0 0 0;
}
:global(.dash-left) .headFlx {
    align-items: center;
}
:global(.dash-left) .headRight {
    margin: 0 0 0;
}
:global(.dash-left) .headLog {
    margin: 0 0 0;
}

/* 01/04/2022 -- END */

.headRight .rgtFlx span{
    margin: 0 6px;
    font-size: 14px;
}
.headRight .rgtFlx span:first-of-type{
    margin-left: 0;
}
.headRight .rgtFlx span:last-of-type{
    margin-right: 0;
}
.headRight .rgtFlx span i{
    margin-right: 8px;
    display: inline-block;
}
.headRight .rgtFlx span a{
    color: #FFF;
    padding-right: 8px;
}
.headRight .rgtFlx span a:hover{
    color: #dd3144;
}